import { TimeRangeOption } from "core/components/datepicker/model";
import CreateRequest from "core/xhr";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGlobalRefresh } from "store/selectors";
import { subscribeData, unsubscribeData } from "store/subscription/api";
import { WidgetMultiDataResponse } from "store/widgets/entities";
import { BuilderSocketReducer } from "store/widgets/reducers";
import { getSocketData } from "store/widgets/selectors";
import { getTimeFromRange } from "views/layouts/app/components/global-date-picker/model";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import { GlobalRouteParams } from "views/layouts/app/routes/model";
import useDidMountEffect from "views/layouts/app/routes/useDidMountEffect";
import { getChartTypeFromAppId } from "../../core/widgets.app";
import {
  builderFiltersInstance,
  builderGroupByInstance,
  builderLimitInstance,
  BuilderProps,
  builderSortingInstance,
  ChartDataType,
  CustomWidget,
  defaultQuery,
  FilterDataType,
  getFilter,
  getWidgetType,
  GLOBAL_ATTRIBUTE_FILTER,
  GridviewDataType,
  ORDER_BY_METRICS,
  Query,
  QueryCountDataType,
  SELECT_DATA_BY,
  SelectOneMetric,
  SubscribeRequest,
  SubscrieDataRequest,
  TimeSeriesData,
  WidgetDataType,
} from "../../entities/builder.entities";
import { ValueType, WithExpr } from "../../entities/extra.entities";
import { GetPayloadRequest, GetPayloadResponse } from "./entities";
import { onSocketResponseReceived } from "./helper";
import usePoller, { Poller } from "./usePoller";
export interface BuilderViewHook {
  getPayload: (payload?: GetPayloadRequest) => GetPayloadResponse;
  requestData: (payload: GetPayloadRequest, caller: string) => void;
  subScribeBuilderData: () => void;
  unSubscribeBuilderData: () => void;
  getChartData: (name: string) => CustomWidget | undefined;
  poller: Poller | undefined;
}
export enum BuilderDataType {
  Resource = "Resource",
  Widget = "Widget",
}
const useBuilderView = (props: BuilderProps, dataType: BuilderDataType) => {
  // Route Params
  const routeData = useContext(MwRouteContext);
  const dispatch = useDispatch();
  const dateRange = routeData.params.dateRange;
  const appliedFilters = routeData.params.appliedFilters;
  // using key: value to store widget data
  // key will be resource name
  // reason to keep it as object is to use same view with multiple resources, ex. Infra
  const [chartData, setChartData] = useState<Record<string, CustomWidget>>();
  const sData = useSelector(getSocketData) as BuilderSocketReducer;

  // Extract Props
  const {
    disableQueryParamChange,
    builderViewOptions,
    additionalBuilderViewOptions,
    isPoller,
  } = props;

  const isGlobalRefresh = useSelector(getGlobalRefresh);
  const poller = usePoller(() => {
    requestData({}, "live");
  });
  useEffect(() => {
    return () => {
      if (routeData.params.isLive) {
        unSubscribeBuilderData();
      }
    };
  }, []);

  useEffect(() => {
    if (sData.data.length) {
      sData.data.forEach((data) => {
        if (chartData) {
          const updateData = onSocketResponseReceived(chartData, data);
          setChartData((prev) => {
            return {
              ...prev,
              ...updateData,
            };
          });
        }
      });
    }
  }, [sData.data.length]);
  const getPayload = (payload?: GetPayloadRequest): GetPayloadResponse => {
    const { widget } = payload ?? {};
    if (!widget)
      return {
        success: false,
      };
    let withExpressions = payload?.withExpressions;
    const appliedFilterKeys = Object.keys(appliedFilters || {});
    //todo:verify reset is not affecting other
    if (appliedFilterKeys.length > 0 && !disableQueryParamChange) {
      const obj: WithExpr = {
        key: GLOBAL_ATTRIBUTE_FILTER,
        value: {},
        is_arg: true,
      };
      const filterConditions: FilterDataType[] = [];
      appliedFilterKeys.forEach((f) => {
        if (!appliedFilters[f]?.values.length) return;
        if (appliedFilters[f].values.length === 0) return;
        const inFilter = getFilter(f, appliedFilters[f]);
        if (!inFilter) return;
        filterConditions.push(inFilter);
      });
      const andFilter = builderFiltersInstance.And(filterConditions);
      if (andFilter) {
        const fFilter = builderFiltersInstance.ConvertToValueType(andFilter);
        if (fFilter) {
          obj.value = fFilter;
          if (withExpressions) {
            withExpressions.push(obj);
          } else {
            withExpressions = [obj];
          }
        }
      }
    }
    if (props.filters) {
      //TODO: add all filters
      let filterValue: ValueType = {};
      if (props.filters.length > 1) {
        const andFilters = builderFiltersInstance.Or(props.filters);
        if (andFilters) {
          const f = builderFiltersInstance.ConvertToValueType(andFilters);
          if (f) {
            filterValue = f;
          }
        }
      } else {
        const f = builderFiltersInstance.ConvertToValueType(props.filters[0]);
        if (f) {
          filterValue = f;
        }
      }
      if (withExpressions && filterValue) {
        const filterIndex = withExpressions.findIndex(
          (w) => w.key === GLOBAL_ATTRIBUTE_FILTER && w.value
        );
        if (filterIndex > -1) {
          const filter = builderFiltersInstance.And([
            withExpressions[filterIndex].value as FilterDataType,
            filterValue as FilterDataType,
          ]);

          if (filter) {
            const value = builderFiltersInstance.ConvertToValueType(filter);

            if (value) {
              withExpressions[filterIndex].value = value;
            }
          }
        } else {
          withExpressions.push({
            key: GLOBAL_ATTRIBUTE_FILTER,
            value: filterValue,
            is_arg: true,
          });
        }
      } else if (filterValue) {
        withExpressions = [
          {
            key: GLOBAL_ATTRIBUTE_FILTER,
            value: filterValue,
            is_arg: true,
          },
        ];
      }
    }
    if (props.groupBy) {
      const obj: WithExpr = {
        key: SELECT_DATA_BY,
        value: props.groupBy,
        is_arg: true,
      };
      withExpressions?.push(obj);
    }
    widget.params = [];
    if (props.extraParams) {
      widget.params.push(...props.extraParams);
    }
    const updatedTimeRange = getTimeFromRange(TimeRangeOption.OneHour);
    const fromTsIndex = widget.params.findIndex(
      (p) => p.key === GlobalRouteParams.FromTs
    );
    const fromTs = props?.dateRange?.fromTs || dateRange.fromTs;
    const updatedFromTs = routeData.params.isLive
      ? updatedTimeRange.fromTs
      : fromTs;
    if (fromTsIndex > -1) {
      widget.params[fromTsIndex].value = updatedFromTs;
    } else {
      widget.params.push({
        key: GlobalRouteParams.FromTs,
        value: updatedFromTs,
      });
    }
    const toTsIndex = widget.params.findIndex(
      (p) => p.key === GlobalRouteParams.ToTs
    );
    const toTs = props?.dateRange?.toTs || dateRange.toTs;
    const updatedToTs = routeData.params.isLive ? updatedTimeRange.toTs : toTs;
    if (toTsIndex > -1) {
      widget.params[toTsIndex].value = updatedToTs;
    } else {
      widget.params.push({
        key: GlobalRouteParams.ToTs,
        value: updatedToTs,
      });
    }
    //TODO: Confirm Logic
    if (withExpressions?.length) {
      const builderConfig = widget.builderConfig;
      if (Array.isArray(builderConfig) && withExpressions) {
        builderConfig.forEach((singleBuilderQuery) => {
          if (!singleBuilderQuery.with) {
            singleBuilderQuery.with = [];
            singleBuilderQuery.with = withExpressions;
          } else {
            if (withExpressions) {
              withExpressions.forEach((w) => {
                if (!singleBuilderQuery.with) {
                  singleBuilderQuery.with = [];
                }
                const keyIndex = singleBuilderQuery.with.findIndex(
                  (k) => k.key === w.key
                );
                if (keyIndex === -1) {
                  singleBuilderQuery.with.push(w);
                } else {
                  singleBuilderQuery.with[keyIndex] = {
                    ...singleBuilderQuery.with[keyIndex],
                    value: Array.isArray(w.value)
                      ? w.value
                      : {
                          ...singleBuilderQuery.with[keyIndex].value,
                          ...w.value,
                        },
                  };
                }
              });
            }
          }
        });
        widget.builderConfig = builderConfig;
      } else if (!Array.isArray(builderConfig) && builderConfig) {
        if (!builderConfig.with) {
          builderConfig.with = [];
          builderConfig.with = withExpressions;
        } else {
          withExpressions.forEach((w) => {
            if (!builderConfig.with) {
              builderConfig.with = [];
            }
            const keyIndex = builderConfig.with.findIndex(
              (k) => k.key === w.key
            );
            if (keyIndex === -1) {
              builderConfig.with.push(w);
            } else {
              if (builderConfig.with[keyIndex].key === ORDER_BY_METRICS) {
                const currentValue = builderConfig.with[keyIndex].value;
                Object.keys(w.value).forEach((key) => {
                  if (key in currentValue) {
                    delete (
                      currentValue as Record<
                        string,
                        string | number | string[] | number[]
                      >
                    )[key];
                  }
                });
                const newValue = Array.isArray(w.value)
                  ? w.value
                  : { ...w.value };
                builderConfig.with[keyIndex] = {
                  ...builderConfig.with[keyIndex],
                  value: newValue,
                };
              }
              builderConfig.with[keyIndex] = {
                ...builderConfig.with[keyIndex],
                value: Array.isArray(w.value)
                  ? w.value
                  : {
                      ...builderConfig.with[keyIndex].value,
                      ...w.value,
                    },
              };
            }
          });
        }
        widget.builderConfig = builderConfig;
      }
    } else {
      const builderConfig = widget.builderConfig;
      if (Array.isArray(builderConfig) && appliedFilterKeys.length == 0) {
        builderConfig.forEach((singleBuilderQuery) => {
          singleBuilderQuery.with = singleBuilderQuery.with?.filter(
            (item) => item.key != GLOBAL_ATTRIBUTE_FILTER
          );
        });
        widget.builderConfig = builderConfig;
      }
    }
    const isDebugEnabled = routeData.params.debug;
    const debuPresentIndex = widget.params?.findIndex(
      (p) => p.key === GlobalRouteParams.Debug
    );
    if (isDebugEnabled) {
      if (debuPresentIndex === -1) {
        if (!widget.params) widget.params = [];
        widget.params?.push({
          key: GlobalRouteParams.Debug,
          value: "true",
        });
      }
    } else if (
      typeof debuPresentIndex !== "undefined" &&
      debuPresentIndex !== -1
    ) {
      widget.params?.splice(debuPresentIndex);
    }
    return {
      widgetRequest: widget,
      success: true,
    };
  };
  const onDataReceived = (
    name: string,
    widget: CustomWidget,
    widgetData?: WidgetDataType
  ) => {
    if (widgetData) {
      const chartType = getChartTypeFromAppId(widget?.widgetAppId || -1);
      const widgetType = getWidgetType(chartType);
      const dataToSet: ChartDataType = {
        inflight: false,
      };
      if (widgetType === "timeseries") {
        dataToSet.timeseriesChartData = widgetData as TimeSeriesData;
      } else if (widgetType === "list") {
        dataToSet.gridviewData = widgetData as GridviewDataType;
      } else if (widgetType === "queryValue") {
        dataToSet.queryCountChartData = widgetData as QueryCountDataType;
      }
      widget.widgetData = dataToSet;
      setChartData((prev) => {
        return {
          ...prev,
          [name]: widget,
        };
      });
    }
  };
  const shouldRequestTotalCount = () => {
    const resource = builderViewOptions?.resource;
    if (
      resource?.widgetAppId === 5 &&
      resource?.resourceType &&
      !(resource.resourceType === "log")
    ) {
      return true;
    }
    return false;
  };
  const requestResourceData = (
    caller: string,
    withExpressions?: WithExpr[]
  ) => {
    if (routeData.params.debug) {
      console.log("requestResourceData called from", caller);
    }
    const resource = builderViewOptions?.resource;
    if (!resource || resource.resourceType === "") {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const userQuery: Query = JSON.parse(JSON.stringify(defaultQuery));
    if (resource.columns?.length) {
      if (resource.columns.find((c) => c.includes(SelectOneMetric))) {
        console.warn("SelectOneMetric is not supported in builder view");
        return;
      }
      userQuery.columns = resource.columns;
      userQuery.source.name = resource.resourceType;
      userQuery.with = resource.with;
      // if (userQuery.with)
      //   userQuery.with = userQuery?.with.filter(
      //     (value, index, self) =>
      //       index === self.findIndex((t) => t.key === value.key)
      //   );
      if (resource.granularity) {
        userQuery.granularity = resource.granularity;
      }
      if (props.sortBy) {
        const sortWith = builderSortingInstance.getValues(props.sortBy);
        if (sortWith) {
          if (!userQuery.with) userQuery.with = [];
          userQuery.with.push(sortWith);
        }
      }
      if (props.groupBy) {
        const groupBy = builderGroupByInstance.getValues(props.groupBy);
        if (groupBy) {
          if (!userQuery.with) userQuery.with = [];
          userQuery.with.push(groupBy);
        }
      }
      if (props.limit) {
        const limit = builderLimitInstance.getValues(props.limit);
        if (limit) {
          if (!userQuery.with) userQuery.with = [];
          userQuery.with.push(limit);
        }
      }
    }
    const userQuerys: Query[] = [];
    if (userQuery.columns?.length) {
      userQuerys.push(userQuery);
    }

    if (
      additionalBuilderViewOptions &&
      additionalBuilderViewOptions.length > 0
    ) {
      additionalBuilderViewOptions.forEach((additionalBuilderViewOption) => {
        const additionalResource = additionalBuilderViewOption?.resource;
        if (additionalResource) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const additionalUserQuery: Query = JSON.parse(
            JSON.stringify(defaultQuery)
          );
          additionalUserQuery.columns = additionalResource.columns;
          additionalUserQuery.source.name = additionalResource.resourceType;
          additionalUserQuery.with = additionalResource.with;
          if (additionalResource.granularity) {
            additionalUserQuery.granularity = additionalResource.granularity;
          }
          if (props.sortBy) {
            const sortWith = builderSortingInstance.getValues(props.sortBy);
            if (sortWith) {
              if (!additionalUserQuery.with) {
                additionalUserQuery.with = [];
              }
              additionalUserQuery.with.push(sortWith);
            }
          }
          if (props.groupBy) {
            const groupBy = builderGroupByInstance.getValues(props.groupBy);
            if (groupBy) {
              if (!additionalUserQuery.with) {
                additionalUserQuery.with = [];
              }
              additionalUserQuery.with.push(groupBy);
            }
          }
          if (props.limit) {
            const limit = builderLimitInstance.getValues(props.limit);
            if (limit) {
              if (!additionalUserQuery.with) {
                additionalUserQuery.with = [];
              }
              additionalUserQuery.with.push(limit);
            }
          }
          if (additionalUserQuery.columns?.length) {
            userQuerys.push(additionalUserQuery);
          }
        }
      });
    }

    const widget: CustomWidget = {
      label: "",
      builderId: 0,
      scopeId: 0,
      widgetAppId: resource.widgetAppId,
      builderConfig: additionalBuilderViewOptions ? userQuerys : userQuery,
      builderViewOptions: {
        resource,
      },
    };
    const { widgetRequest, success } = getPayload({
      widget,
      withExpressions,
    });
    if (!success) {
      return;
    }
    makeRequestAndSaveData(widgetRequest, resource.name, resource.resourceType);
  };
  const makeRequestAndSaveData = (
    widgetRequest: CustomWidget | undefined,
    name: string,
    resourceType: string
  ) => {
    if (!widgetRequest) {
      return;
    }
    if (
      !props.allowMultipleRequest &&
      chartData?.[name]?.widgetData?.inflight
    ) {
      return;
    }

    widgetRequest.widgetData = {
      inflight: routeData.params.isLive ? false : true,
    };
    if (!chartData?.[name]) {
      setChartData((prev) => {
        return {
          ...prev,
          [name]: widgetRequest,
        };
      });
    } else {
      setChartData((prev) => {
        if (!prev?.[name]) {
          return {
            ...prev,
            [name]: widgetRequest,
          };
        }

        return routeData.params.isLive
          ? {
              ...prev,
              [name]: {
                ...prev[name],
              },
            }
          : {
              ...prev,
              [name]: {
                ...prev[name],
                widgetData: {
                  inflight: true,
                },
              },
            };
      });
    }
    const requests = [widgetRequest];
    const isTotalCountRequested = shouldRequestTotalCount();
    if (isTotalCountRequested && resourceType) {
      const totalCountRequests = getTotalCountRequests(resourceType);
      if (totalCountRequests.length) {
        requests.push(...totalCountRequests);
      }
    }
    const qString = "?req=resource=" + name;
    CreateRequest({
      url: `/builder/widget/multi-data` + qString,
      method: "POST",
      data: requests,
    })
      .then((res: WidgetMultiDataResponse) => {
        if (res.status === 500) {
          requests[0].widgetData = {
            inflight: false,
          };
          requests[0].isError = true;
          requests[0].errorMsg = "Something went wrong";
          setChartData((prev) => ({
            ...prev,
            [name]: requests[0],
          }));
          return;
        }
        if (res.status === 200) {
          if (res?.data?.[0]?.chart_data) {
            let dataToSet = res.data[0].chart_data;
            if (isTotalCountRequested) {
              const data = res.data[0].chart_data as GridviewDataType;
              // up count data
              if (requests.length > 1) {
                const upCountData = res.data[1]
                  .chart_data as QueryCountDataType;
                if (upCountData?.[0]?.name) {
                  data.up_count_data = upCountData[0].value + "";
                }
              }
              // total count data
              if (requests.length > 2) {
                const totalCountData = res.data[2]
                  .chart_data as QueryCountDataType;
                if (totalCountData?.[0]?.name) {
                  data.total_count = totalCountData[0].value;
                }
              }
              dataToSet = data;
            }
            onDataReceived(name, requests[0], dataToSet);
          } else {
            requests[0].widgetData = {
              inflight: false,
            };
            requests[0].isError = true;
            requests[0].errorMsg = "Something went wrong";
            setChartData((prev) => ({
              ...prev,
              [name]: requests[0],
            }));
          }
        } else {
          requests[0].widgetData = {
            inflight: false,
          };
          requests[0].isError = true;
          requests[0].errorMsg = "Something went wrong";
          setChartData((prev) => ({
            ...prev,
            [name]: requests[0],
          }));
        }
      })
      .catch((e) => {
        console.log("e---", e);
        requests[0].widgetData = {
          inflight: false,
        };
        requests[0].isError = true;
        requests[0].errorMsg = "Something went wrong";
        setChartData((prev) => ({
          ...prev,
          [name]: requests[0],
        }));
      });
  };

  const getTotalCountRequests = (resourceType: string): CustomWidget[] => {
    if (!resourceType) return [];
    const withCountExpr = [] as WithExpr[];
    if (props.builderViewOptions?.resource?.with) {
      const withExp = props.builderViewOptions?.resource?.with;
      withExp.forEach((item) => {
        if (item.key == SELECT_DATA_BY) {
          const obj: WithExpr = {
            key: SELECT_DATA_BY,
            value: item.value,
            is_arg: true,
          };
          withCountExpr.push(obj);
        }
      });
    }

    const upCountWidget: CustomWidget = {
      label: "",
      builderId: 0,
      scopeId: 0,
      widgetAppId: 12,
      builderConfig: {
        source: {
          name: resourceType,
          alias: "total_count",
          dataset_id: 0,
        },
        columns: ["up_out_of_total"],
      },
      builderViewOptions: {
        displayScope: "",
      },
    };
    const upCountWidgetPayload = getPayload({
      widget: upCountWidget,
    });
    if (upCountWidgetPayload.widgetRequest?.builderConfig) {
      const builderConfig = upCountWidgetPayload.widgetRequest.builderConfig;

      if (Array.isArray(builderConfig)) {
        // Handle the case where builderConfig is an array of Query objects
        if (!builderConfig[0].with) {
          builderConfig[0].with = withCountExpr;
        } else {
          builderConfig[0].with.push(...withCountExpr);
        }
      } else {
        // Handle the case where builderConfig is a single Query object
        if (!builderConfig.with) {
          builderConfig.with = withCountExpr;
        } else {
          builderConfig.with.push(...withCountExpr);
        }
      }
    }

    const totalCountRequest: CustomWidget = {
      label: "",
      builderId: 0,
      scopeId: 0,
      widgetAppId: 12,
      builderConfig: {
        source: {
          name: resourceType,
          alias: "total_count",
          dataset_id: 0,
        },
        columns: ["total_rows_count"],
      },
      builderViewOptions: {
        displayScope: "",
      },
    };
    const totalCountPayload = getPayload({
      widget: totalCountRequest,
    });
    if (upCountWidgetPayload.widgetRequest && totalCountPayload.widgetRequest) {
      const requests = [
        upCountWidgetPayload.widgetRequest,
        totalCountPayload.widgetRequest,
      ];
      return requests;
    }
    return [];
  };
  const requestWidgetData = (
    caller: string,
    action: string,
    payload: GetPayloadRequest
  ) => {
    if (routeData.params.debug) {
      console.log("requestWidgetData called from", caller);
    }
    const { widget } = payload || {};
    if (!widget) return;
    if (!widget) return;
    const { widgetRequest, success } = getPayload(payload);
    if (!widgetRequest || !success) {
      return;
    }
    // const data: AddUpdateWidgetBuilder = {
    //   action,
    //   body: widgetRequest,
    //   inflight: true,
    // };
    // dispatch(requestBuilderFormattedData(data));
    const deepCopyWidgetRequest = JSON.parse(
      JSON.stringify(widgetRequest)
    ) as CustomWidget;
    makeRequestAndSaveData(
      deepCopyWidgetRequest,
      widgetRequest.builderId + "",
      ""
    );
  };
  const requestData = (payload: GetPayloadRequest, caller: string) => {
    // return;
    if (dataType === BuilderDataType.Resource) {
      requestResourceData(caller, payload.withExpressions);
    } else if (dataType === BuilderDataType.Widget && payload.widget) {
      requestWidgetData("useBuilderView", "add", payload);
    }
  };
  const subscribeResource = () => {
    const resource = builderViewOptions?.resource;
    if (resource && resource.resourceType !== "") {
      const data: SubscribeRequest = {
        event: "builder",
        type: "metric",
        requests: [],
      };
      const builderData: SubscrieDataRequest[] = [];
      let groupType: string[] = [];
      if (resource.with) {
        resource.with.forEach((w) => {
          if (w.key === SELECT_DATA_BY && Array.isArray(w.value)) {
            groupType = w.value;
          }
        });
      }
      if (props.groupBy?.length) {
        groupType = props.groupBy;
      }
      builderData.push({
        uniqueId: resource.name,
        resource: resource.resourceType,
        columns: resource.columns,
        groupBy: groupType,
        metricAttributes: resource.metricAttributes,
        widgetAppId: resource.widgetAppId,
      });
      data.requests = builderData;
      data.filter_request = {
        clientLocation: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      if (!routeData.params.isLive) {
        return;
      }
      dispatch(
        subscribeData({
          body: data,
        })
      );
    }
  };

  useDidMountEffect(() => {
    requestData({}, "JSON.stringify(appliedFilters)");
  }, [JSON.stringify(appliedFilters)]);
  useDidMountEffect(() => {
    // call api to get data only if range is not handled by parent
    // For ex. timeline
    if (!props.dateRange) {
      requestData({}, "routeData.params.dateRange");
    }
  }, [routeData.params.dateRange]);
  useDidMountEffect(() => {
    // call api to get data only if range is not handled by parent
    // For ex. timeline
    requestData({}, "props.dateRange");
  }, [props.dateRange?.fromTs, props.dateRange?.toTs]);

  useDidMountEffect(() => {
    if (routeData.params.isLive) {
      //Poller Approach
      subScribeBuilderData();
    } else {
      unSubscribeBuilderData();
    }
  }, [routeData.params.isLive]);

  useDidMountEffect(() => {
    if (dataType === BuilderDataType.Resource) {
      requestData({}, "isGlobalRefresh");
    }
  }, [isGlobalRefresh]);
  const subScribeBuilderData = () => {
    if (!isPoller && dataType === BuilderDataType.Resource) {
      subscribeResource();
    } else if (isPoller && dataType === BuilderDataType.Resource) {
      poller?.start();
    }
  };
  const unSubscribeBuilderData = () => {
    if (!isPoller) {
      dispatch(unsubscribeData("builder"));
    } else {
      poller?.stop();
    }
  };
  const data: BuilderViewHook = {
    getPayload,
    requestData,
    subScribeBuilderData,
    unSubscribeBuilderData,
    getChartData: (name: string) => {
      if (name) {
        return chartData?.[name];
      }
      return;
    },
    poller,
  };
  return data;
};
export default useBuilderView;
