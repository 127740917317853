import { User } from "core/application/model";
import { Dayjs } from "dayjs";
import mixpanel from "mixpanel-browser";
import moment, { Moment } from "moment";
import { RUM_MOBILE_LANGUAGE } from "store/rum/constant";
import { QueryParams } from "views/layouts/app/routes/model";
import { FilterOpEnum } from "views/modules/builder/entities/builder.entities";

const possibleRegularExpressions: any = {
  1: /[0-9]{1,4}\-[0-9]{1,3}\-[0-9]{1,3}[a-zA-Z]{1,3}[0-9]{1,3}\:[0-9]{1,4}\:[0-9]{1,4}\.[0-9]{1,4}[a-zA-Z]{1,5}/gm,
  2: /[0-9]{4}\/[0-9]{2}\/[0-9]{2}/gm,
  3: /[[\]-]+/gm,
  4: /[0-9]{2}\:[0-9]{2}\:[0-9]{2}/gm,
  5: /[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\:[0-9]{1,4}/gm,
  6: /[0-9]{1,2}\/[a-zA-Z]{1,4}\/[0-9]{1,4}/gm,
  7: /:[0-9]{1,4}/gm,
  8: /<[a-zA-Z]{1,9}>/gm,
  9: /[0-9]{1,8}[.:]/gm,
  10: /[{\}-]+/gm,
  11: /[.:][ ]{1,20}/gm,
  12: /[.:][0-9]{1,10}/gm,
};

const diffInMinutes = (ts1: any) => {
  ts1 = new Date(ts1);
  const ts2 = ((new Date() as any) / 1000) * 1000;
  const diffTime = Math.abs(ts2 - ts1);
  return Math.ceil(diffTime / (1000 * 60));
};

const diffYMDHMS = (
  date1: Moment,
  date2: Moment,
  isAllowMs = false,
  isShorthand = false
) => {
  let ms = 0;

  const years = date1.diff(date2, "year");
  date2.add(years, "years");

  const months = date1.diff(date2, "months");
  date2.add(months, "months");

  const days = date1.diff(date2, "days");
  date2.add(days, "days");

  const hours = date1.diff(date2, "hours");
  date2.add(hours, "hours");

  const minutes = date1.diff(date2, "minutes");
  date2.add(minutes, "minutes");

  const seconds = date1.diff(date2, "seconds");

  if (isAllowMs) {
    date2.add(seconds, "seconds");
    ms = date1.diff(date2, "ms");
  }

  let formatted = "";
  if (years) formatted += years + (years > 1 ? " years " : " years ");
  if (months) formatted += months + (months > 1 ? " months " : " months ");
  if (days) formatted += days + (days > 1 ? " days " : " day ");
  if (hours) formatted += hours + " hr ";
  if (minutes) formatted += minutes + (!isShorthand ? " min " : "m ");
  if (seconds) formatted += seconds + (!isShorthand ? " sec " : "s ");
  if (isAllowMs && ms) formatted += ms + (!isShorthand ? " ms " : "ms ");

  return { years, months, days, hours, minutes, seconds, formatted };
};

const agoTime = (
  date1: Dayjs,
  date2: Dayjs,
  isAllowMs = false,
  isShorthand = false
) => {
  let ms = 0;

  const years = date1.diff(date2, "year");
  date2.add(years, "years");

  const months = date1.diff(date2, "months");
  date2.add(months, "months");

  const days = date1.diff(date2, "days");
  date2.add(days, "days");

  const hours = date1.diff(date2, "hours");
  date2.add(hours, "hours");

  const minutes = date1.diff(date2, "minutes");
  date2.add(minutes, "minutes");

  const seconds = date1.diff(date2, "seconds");

  if (isAllowMs) {
    date2.add(seconds, "seconds");
    ms = date1.diff(date2, "ms");
  }

  let formatted = "";
  if (isAllowMs && ms) formatted = ms + (!isShorthand ? " ms" : "ms");
  if (seconds) formatted = seconds + (!isShorthand ? " sec" : "s");
  if (minutes)
    formatted =
      minutes === 1 ? "a min" : minutes + (!isShorthand ? " min" : "m");
  if (hours) formatted = hours === 1 ? "a hr" : hours + " hrs";
  if (days) formatted = days + (days > 1 ? " days" : " day");
  if (months) formatted = months + (months > 1 ? " months" : " months");
  if (years) formatted = years + (years > 1 ? " years" : " years");

  return { years, months, days, hours, minutes, seconds, formatted };
};

/*function formatBytes(bytes: any) {
    const marker = 1024; // Change to 1000 if required
    const decimal = 2; // Change as required
    const kiloBytes = marker; // One Kilobyte is 1024 bytes
    const megaBytes = marker * marker; // One MB is 1024 KB
    const gigaBytes = marker * marker * marker; // One GB is 1024 MB

    // return bytes if less than a KB
    if(bytes < kiloBytes) return bytes + " Bytes";
    // return KB if less than a MB
    else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
    // return MB if less than a GB
    else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
    // return GB if less than a TB
    else return(bytes / gigaBytes).toFixed(decimal) + " GB";
}*/

function formatBytes(b: number, decimals = 2) {
  if (!+b) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(b) / Math.log(k));

  if (!sizes[i]) {
    return b.toString() + " Bytes";
  }

  return `${parseFloat((b / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

function stripoutHtmlTags(s: string) {
  return s.replace(/(<([^>]+)>)/gi, "");
}

function towDigitNumber(n: number) {
  return n > 9 || n == 0 ? n : "0" + n;
}

function abbreviateNumber(
  n: number,
  twoDigit = false,
  toCapitalCase?: boolean
) {
  if (n < 1e3)
    return twoDigit
      ? towDigitNumber(n)
      : typeof n === "number"
        ? n.toFixed(2).replace(/\.00$/, "").toString()
        : n + "";
  if (n >= 1e3 && n < 1e6)
    return +(n / 1e3).toFixed(2) + (toCapitalCase ? "K" : "k");
  if (n >= 1e6 && n < 1e9)
    return +(n / 1e6).toFixed(2) + (toCapitalCase ? "M" : "m");
  if (n >= 1e9 && n < 1e12)
    return +(n / 1e9).toFixed(2) + (toCapitalCase ? "B" : "b");
  if (n >= 1e12) return +(n / 1e12).toFixed(2) + "t";
}

function getCookie(cname: any) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.startsWith(" ")) {
      c = c.substring(1);
    }
    if (c.startsWith(name)) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function applyLogsRegularExpression(str: string) {
  Object.keys(possibleRegularExpressions).forEach((x) => {
    str = str.replace(possibleRegularExpressions[x], "");
  });

  return str;
}

function customSort({
  data,
  sortBy,
  sortField,
}: {
  data: any;
  sortBy: any;
  sortField: string;
}) {
  const sortByObject = sortBy.reduce(
    (obj: object, item: string, index: number) => {
      return {
        ...obj,
        [item]: index,
      };
    },
    {}
  );
  return data.sort(
    (a: any, b: any) => sortByObject[a[sortField]] - sortByObject[b[sortField]]
  );
}

function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function parsePercentage(num: number) {
  const _num = num > 0 ? parseFloat(parseFloat(String(num)).toFixed(2)) : 0.0;
  return _num <= 100 ? _num : 100;
}

function truncate(source: string, size: number) {
  return source.length > size ? source.slice(0, size - 1) + "…" : source;
}
const isNanoseconds = (seconds: number) => seconds > 1e15;
const convertMillisSecondsToNanoSeconds = (milliSeconds: number) =>
  milliSeconds * 1000000;
const convertNanoSecondsToSeconds = (nanoSeconds: number) =>
  nanoSeconds / 1000000000;
const converMicroSecondsToSeconds = (microSeconds: number) =>
  microSeconds / 1000000;
const convertMillisSecondsToSeconds = (milliSeconds: number) =>
  milliSeconds / 1000;
const convertMinutesToSeconds = (minutes: number) => minutes * 60;
const convertHoursToSeconds = (hours: number) => hours * 60 * 60;
const convertDaysToSeconds = (days: number) => days * 24 * 60 * 60;
const convertWeeksToSeconds = (weeks: number) => weeks * 7 * 24 * 60 * 60;
const convertMonthsToSeconds = (months: number) => months * 30 * 24 * 60 * 60;
const convertYearsToSeconds = (years: number) => years * 365 * 24 * 60 * 60;
const convertValueToSeconds = (value: number, unit: string) => {
  switch (unit) {
    case "nanosecond":
      return convertNanoSecondsToSeconds(value);
    case "microsecond":
      return converMicroSecondsToSeconds(value);
    case "millisecond":
      return convertMillisSecondsToSeconds(value);
    case "second":
      return Math.ceil(value);
    case "minute":
      return convertMinutesToSeconds(value);
    case "hour":
      return convertHoursToSeconds(value);
    case "day":
      return convertDaysToSeconds(value);
    case "week":
      return convertWeeksToSeconds(value);
    case "month":
      return convertMonthsToSeconds(value);
    case "year":
      return convertYearsToSeconds(value);
    default:
      return value;
  }
};
const getQueryParams = (query: URLSearchParams): QueryParams => {
  const paramsRequest: QueryParams = {};
  const params = Object.fromEntries(query.entries());
  for (const _key in params) {
    if (params[_key] && params[_key] !== "") {
      paramsRequest[_key] = {
        operator: FilterOpEnum.Equal,
        values: params[_key].split(","),
      };
    }
  }
  return paramsRequest;
};
export interface RequestError {
  key: string;
  message: string;
}
export enum APIStatus {
  IDLE = "idle",
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
  BAD_REQUEST = "bad_request",
  LOADED = "loaded",
}

export const isDebugEnabled = () => {
  return getQueryParams(new URLSearchParams(window.location.search)).debug;
};
export const isQueryTypeAvailable = () => {
  return getQueryParams(new URLSearchParams(window.location.search)).query_type;
};

const copyToClipboard = (text: string) => {
  return navigator.clipboard.writeText(text);
};

const escapeHtml = (unsafeHtml: any) => {
  return unsafeHtml?.replace(/</g, "&lt;")?.replace(/>/g, "&gt;");
};
export const GetDatePartsFromTs = (ts: number) => {
  const date = new Date(ts);
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
    seconds: date.getSeconds(),
    milliseconds: date.getMilliseconds(),
  };
};
export const IsDateChanged = (ts1: number, ts2: number) => {
  const currentDay = GetDatePartsFromTs(ts1);
  const selectedDay = GetDatePartsFromTs(ts2);
  return (
    currentDay.year !== selectedDay.year ||
    currentDay.month !== selectedDay.month ||
    currentDay.day !== selectedDay.day
  );
};
export const EncodeStringToShortUrl = (str: string) => {
  try {
    const s = btoa(str)
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
    return s;
  } catch (error) {
    console.log(error);
    return str;
  }
};
export const DecodeShortUrlToString = (str: string) => {
  try {
    const s = atob(str.replace(/-/g, "+").replace(/_/g, "/"));
    return s;
  } catch (error) {
    console.log(error);
    return str;
  }
};

export function replaceUnderscoreAndCapitalize(input: string): string {
  // Split the input string by underscores
  const words = input.split("_");

  // Capitalize each word and join them with spaces
  const capitalizedWords = words.map((word) => {
    // Capitalize the first letter of the word and make the rest lowercase
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Join the capitalized words with spaces
  const result = capitalizedWords.join(" ");

  return result;
}

export function isValidRegex(regexString: string): boolean {
  try {
    // Attempt to create a new RegExp object
    new RegExp(regexString);
    return true; // If successful, return true
  } catch (error) {
    if (error instanceof SyntaxError) {
      return false; // If SyntaxError thrown, return false
    } else {
      throw error; // If any other error occurs, rethrow it
    }
  }
}

export const isMobileApplication = (language: string) => {
  const languages = new Set(RUM_MOBILE_LANGUAGE);
  return languages.has(language);
};

export const getTenantUrl = (user: User | undefined): string => {
  if (!user?.account?.app_host) return "";
  return user?.account?.project_uid + "." + user?.account?.app_host;
};
export const getDateLabel = (fromDate: Date, toDate: Date) => {
  const notSame =
    `${fromDate.getMonth()}_${fromDate.getDate()}` !==
    `${toDate.getMonth()}_${toDate.getDate()}`;
  const text = `${getSingleDateLabel(fromDate)} - ${getSingleDateLabel(toDate, notSame ? "MMM DD HH:mm" : "HH:mm")}`;
  return text;
};
export const getSingleDateLabel = (date: Date, format?: string) => {
  if (!format) {
    format = "MMM DD HH:mm";
  }
  return `${moment(date).format("MMM DD HH:mm")}`;
};

export interface MixPanelUserAttributes {
  name: string;
  email: string;
  plan: string;
  registered_at?: string;
  already_installed?: boolean;
}

export const initMixPanel = (
  env: string,
  uid: string,
  attr: MixPanelUserAttributes,
  is_internal: boolean
) => {
  let mixPanelToken = "0754d6438ca80b5873e414ebf72600a9abc";
  if (env === "prod") {
    mixPanelToken = "43d635c6c81f05a86c968d3cee54663d";
  }
  const isProduction = isProdWithDisabledUid();
  if (isProduction) {
    mixpanel.init(mixPanelToken, {
      debug: false,
      track_pageview: false,
      persistence: "localStorage",
    });
    if (uid.length > 0) {
      mixpanel.identify(uid);
      mixpanel.people.set({
        $name: attr.name,
        $email: attr.email,
        $plan: attr.plan,
      });
      mixpanel.register({
        name: attr.name,
        email: attr.email,
        registered_at: attr.registered_at,
        already_installed: attr.already_installed,
      });
    }
  }
};

export const disabledAccountUids: string[] = [
  "medhp",
  "demo2",
  "p2i13hg",
  "beta-bkoff",
  "mwdemo",
  "mw-prod",
  "qaprod",
  "zyriq",
];
export const isProdWithDisabledUid = () => {
  let isProd = false;
  const url = window.location.href;
  const excludeUidList: string[] = disabledAccountUids;
  const uid = url.split(".")[0].split("//")[1];

  const isExcludedUid = excludeUidList.includes(uid);
  const isProdUrl =
    url.includes("middleware.io") &&
    !url.includes(".env.middleware.io") &&
    url.indexOf("bkoff.middleware.io") < 0 &&
    url.indexOf(".mw.lc") < 0;
  if (isProdUrl && !isExcludedUid) {
    isProd = true;
  }
  return isProd;
};
export {
  abbreviateNumber,
  agoTime,
  applyLogsRegularExpression,
  capitalizeFirstLetter,
  converMicroSecondsToSeconds,
  convertDaysToSeconds,
  convertHoursToSeconds,
  convertMillisSecondsToNanoSeconds,
  convertMillisSecondsToSeconds,
  convertMinutesToSeconds,
  convertMonthsToSeconds,
  convertNanoSecondsToSeconds,
  convertValueToSeconds,
  convertWeeksToSeconds,
  convertYearsToSeconds,
  copyToClipboard,
  customSort,
  diffInMinutes,
  diffYMDHMS,
  escapeHtml,
  formatBytes,
  getCookie,
  getQueryParams,
  isNanoseconds,
  parsePercentage,
  stripoutHtmlTags,
  truncate,
};
