import React from "react";

import { OpenTelemetry } from "core/components/svg/icons";
import {
  AwsEbsIcon,
  AwsEc2Icon,
  AwsEcsIcon,
  AwsElasticacheIcon,
  AwsElbIcon,
  AwsFirehoseIcon,
  AwsKinesisIcon,
  AwsLambdaIcon,
  AwsLexIcon,
  AwsRdsIcon,
  AwsS3Icon,
  AwsSnsIcon,
  AwsSqsIcon,
  AwsWafIcon,
} from "core/components/v2/svg/aws-icons";
import {
  CassandraIcon,
  ClickHouseIcon,
  ElasticSearchIcon,
  MariadbIcon,
  MongoDBIcon,
  MySQLIcon,
  OracleIcon,
  PostgreSQLIcon,
  RedisIcon,
} from "core/components/v2/svg/database-icons";
import {
  ApacheIcon,
  AwsIcon,
  JiraIcon,
  MsTeamsIcon,
  OpsgenieIcon,
  PagerdutyIcon,
  PrometheusIcon,
  SlackIcon,
} from "core/components/v2/svg/integrations-icons";
import {
  KafkaIcon,
  RedPandaIcon,
} from "core/components/v2/svg/streaming-icons";
import { ColumnProps } from "core/components/v2/table-view/entity";
import { getThemeState } from "core/utils";
import { SortDirection } from "../builder/entities/builder.entities";
import { MetricsData } from "../settings-v2/metrics-control-panel/entities";
import { SectionItems } from "./entity";

import MariaDBConfigGuide from "./pages/mariadb/db-config";
import MySQLDatabaseConfigGuide from "./pages/mysql/db-config";
import PostgresDatabaseConfigGuide from "./pages/postgresql/db-config";

export const IntegrationConfigKey = {
  Jira: "Jira",
  MsTeam: "MicrosoftTeams",
  Opsgenie: "Opsgenie",
  PagerDuty: "PagerDuty",
  Slack: "Slack",
  Postgres: "postgres_config",
  MongoDb: "mongodb_config",
  MySQL: "mysql_config",
  MariaDB: "mariadb_config",
  Redis: "redis_config",
  Clickhouse: "clickhouse_config",
  Cassandra: "cassandra_config",
  ElasticSearch: "elasticsearch_config",
  Prometheus: "prometheus_config",
  Redpanda: "redpanda_config",
  Kafka: "kafka_config",
  OracleDB: "oracledb_config",
  Apache: "apache_config",
  AWS: "aws_config",
  AWSEc2: "aws_ec2_config",
  AWSS3: "aws_s3_config",
  AWSRds: "aws_rds_config",
  AWSEcs: "aws_ecs_config",
  AWSEbs: "aws_ebs_config",
  AWSFirehose: "aws_firehose_config",
  AWSLambda: "aws_lambda_config",
  AWSWaf: "aws_waf_config",
  AWSLex: "aws_lex_config",
  OpenTelemetryLogs: "openTelemetry_logs_config",
  OpenTelemetryMetrics: "openTelemetry_metrics__config",
  AWSKinesis: "aws_kinesis_config",
  AWSElb: "aws_elb_config",
  AWSSns: "aws_sns_config",
  AWSSqs: "aws_sqs_config",
  AWSElasticache: "aws_elasticache_config",
};

export const IntegrationAppKey = {
  Jira: "jira",
  MsTeam: "ms-teams",
  Opsgenie: "opsgenie",
  PagerDuty: "pagerduty",
  Slack: "slack",
  PostgreSQL: "postgresql",
  MongoDB: "mongodb",
  MySQL: "mysql",
  MariaDB: "mariadb",
  Redis: "redis",
  Clickhouse: "clickhouse",
  Cassandra: "cassandra",
  ElasticSearch: "elasticsearch",
  Prometheus: "prometheus",
  Redpanda: "redpanda",
  Kafka: "kafka",
  OracleDB: "oracle",
  Apache: "apache",
  AWS: "aws",
  AWSEc2: "aws_ec2",
  AWSS3: "aws_s3",
  AWSRds: "aws_rds",
  AWSEcs: "aws_ecs",
  AWSEbs: "aws_ebs",
  AWSFirehose: "aws_firehose",
  AWSLambda: "aws_lambda",
  AWSWaf: "aws_waf",
  AWSLex: "aws_lex",
  OpenTelemetryLogs: "openTelemetry_logs",
  OpenTelemetryMetrics: "openTelemetry_metrics",
  AWSKinesis: "aws_kinesis",
  AWSElb: "aws_elb",
  AWSSns: "aws_sns",
  AWSSqs: "aws_sqs",
  AWSElasticache: "aws_elasticache",
};

export const CollaborationTitle = {
  Jira: "Jira",
  MsTeams: "Microsoft Teams",
  Opsgenie: "Opsgenie",
  PagerDuty: "PagerDuty",
  Slack: "Slack",
};

export const configComponents = {
  [IntegrationAppKey.PostgreSQL]: <PostgresDatabaseConfigGuide />,
  [IntegrationAppKey.MySQL]: <MySQLDatabaseConfigGuide />,
  [IntegrationAppKey.MariaDB]: <MariaDBConfigGuide />,
};

export interface CollaborationIntegrationProps {
  title: string;
  description: string;
  fields: {
    key: string;
    label?: string;
    placeholder?: string;
  }[];
}

export const databaseTableCols: ColumnProps[] = [
  {
    title: "Key",
    columnKey: "originalKey",
    render: (_, record) => (
      <>{(record as MetricsData)?.metadata?.originalKey ?? "-"}</>
    ),
    defaultSortOrder: SortDirection.Asc,
  },
  {
    title: "Description",
    columnKey: "description",
    render: (_, record) => (
      <>{(record as MetricsData).metadata?.description ?? "-"}</>
    ),
  },
];

export const awsResourceTableCols: ColumnProps[] = [
  {
    title: "Key",
    columnKey: "originalKey",
    render: (_, record) => <>{(record as any)?.key}</>,
    defaultSortOrder: SortDirection.Asc,
    width: 200,
  },
  {
    title: "Unit",
    columnKey: "unit",
    render: (_, record) => <>{(record as any)?.unit ?? "-"}</>,
    width: 100,
  },
  {
    title: "Description",
    columnKey: "description",
    render: (_, record) => <>{(record as any).description ?? "-"}</>,
    width: 700,
  },
];

export const IntegrationItems: () => SectionItems[] = () => {
  const themeMode = getThemeState();
  return [
    {
      label: "Jira",
      link: IntegrationAppKey.Jira,
      prefixicon: <JiraIcon />,
      category: ["All Integration", "Collaboration"],

      integrationProps: {
        configKey: IntegrationConfigKey.Jira,
        bgColor: `rgba(38, 132, 255, ${themeMode === "dark" ? "0.50" : "0.1"})`,
        shortDesc:
          "Jira is a project management and issue tracking platform built by Attlassian.",
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/collaboration"
            )
        ),
        overview: {
          description:
            "The Middleware Jira Integration allows Middleware users to convert alerts and issues on deployments to actionable Jira issues. Your comment screenshots and replies will also appear in your ticket.",
          image: ["assets/images/jira-integration.png"],
          integrationPoints: (
            <>
              <ul>
                <li>Create and update issues using @jira mentions in</li>
                <li> monitor notifications Receive a Datadog event each </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "What fields are supported in the bi-directional Jira integration?",
              desc: "To use the bi-directional Jira integration, you need to make sure your Jira instance is accessible from the Middleware system. You can allowlist Middleware's IP addresses in your firewall to only open it up to the application. Contact your program manager to request our IP addresses to allowlist in your firewall. If you don't want to expose your Jira to Middleware, contact us to get a work-around that enables you to only escalate to Jira.",
            },
          ],

          docLink: "https://docs.middleware.io/integrations/jira-integration",
        },
      },

      collaborationProps: {
        title: CollaborationTitle.Jira,
        description: `Enter the API key below Input box and click on button below to
      install the Middleware app into your Jira workspace`,
        fields: [
          {
            key: "email",
          },
          {
            key: "auth_token",
          },
          {
            key: "url",
          },
        ],
      },
    },
    {
      label: "Microsoft Teams",
      link: IntegrationAppKey.MsTeam,
      prefixicon: <MsTeamsIcon />,
      category: ["All Integration", "Collaboration"],
      integrationProps: {
        configKey: IntegrationConfigKey.MsTeam,
        shortDesc:
          "Microsoft Teams is a collaboration application developed by Microsoft.",
        bgColor: `${themeMode === "dark" ? "rgba(91, 95, 199, 0.5)" : "rgba(123, 131, 235, 0.1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/collaboration"
            )
        ),
        overview: {
          description:
            "The Microsoft Teams integration allows you to directly send alerts to members of your organization. Create custom alerts and receive notifications in any channel or thread within your MS Teams account.",
          image: ["assets/images/ms-integration.png"],
          integrationPoints: (
            <>
              <ul>
                <li>
                  Receive real-time notifications so you can quickly respond to
                  important events.
                </li>
                <li>Choose your preferred communication platform.</li>
                <li>
                  Centralize your communications and streamline your workflows.
                </li>
                <li>Customize your notification settings.</li>
                <li>Automate your alerts.</li>
              </ul>
            </>
          ),
          faqs: [
            {
              title: "Does it work in private channels?",
              desc: "Yes, It does allow to work with private channels",
            },
          ],

          docLink:
            "https://docs.middleware.io/integrations/microsoft-team-integration",
        },
      },
      collaborationProps: {
        title: CollaborationTitle.MsTeams,
        description: ` Click on the button below to install the Middleware app into your
      Microsoft Teams workspace`,
        fields: [],
      },
    },
    {
      label: "Opsgenie",
      link: IntegrationAppKey.Opsgenie,
      prefixicon: <OpsgenieIcon />,
      category: ["All Integration", "Collaboration"],
      integrationProps: {
        configKey: IntegrationConfigKey.Opsgenie,
        shortDesc: "Opsgenie is an incident management and alerting tool.",
        bgColor: `rgba(38, 132, 255, ${themeMode === "dark" ? 0.5 : 0.1})`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/collaboration"
            )
        ),
        overview: {
          description:
            "The Opsgenie Integration allows you to create and assign incidents to team members within your Opsgenie account. Track the total amount of resources consumed by your incident management tool in the Middleware platform.",
          image: [""],
          integrationPoints: (
            <>
              <ul>
                <li>
                  Trigger alerts based on predefined thresholds or anomalies
                  detected in your monitored systems. When an alert is
                  triggered, it can be automatically sent to Opsgenie.
                </li>
                <li>
                  Get detailed alert data in your incident reporting tool.
                </li>
                <li>
                  Enable seamless alert routing and incident management for
                  efficient incident response.
                </li>
                <li>
                  Receive timely alerts, escalate incidents, and collaborate
                  effectively to resolve issues faster.
                </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title: "How to get Opsgenie API key ?",
              desc: (
                <div>
                  You can refer{" "}
                  <a
                    href="https://docs.middleware.io/integrations/opsgenie-integration#to-obtain-an-api-key-from-opsgenie-please-follow-these-steps"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Opsgenie API key section
                  </a>{" "}
                  to get more idea on this.
                </div>
              ),
            },
          ],

          docLink:
            "https://docs.middleware.io/integrations/opsgenie-integration",
        },
      },
      collaborationProps: {
        title: CollaborationTitle.Opsgenie,
        description: `Enter the API key below Input box and click on button below to
      install
      the Middleware app into your Opsgenie workspace`,
        fields: [{ key: "access_token" }],
      },
    },
    {
      label: "Pagerduty",
      link: IntegrationAppKey.PagerDuty,
      prefixicon: <PagerdutyIcon />,
      category: ["All Integration", "Collaboration"],
      integrationProps: {
        configKey: IntegrationConfigKey.PagerDuty,
        shortDesc:
          "PagerDuty is an incident management platform that enables teams to identify and resolve issues.",
        bgColor: `rgba(6, 172, 56, ${themeMode === "dark" ? 0.5 : 0.1})`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/collaboration"
            )
        ),
        overview: {
          description:
            "The PagerDuty Integration allows you to create and assign incidents to team members within your PagerDuty account. Track the total amount of resources consumed by your incident management tool in the Middleware platform.",
          image: [""],
          integrationPoints: (
            <>
              <ul>
                <li>
                  Trigger alerts based on predefined thresholds or anomalies
                  detected in your monitored systems. When an alert is
                  triggered, it can be automatically sent to PagerDuty.
                </li>
                <li>
                  Get detailed alert data in your incidence reporting tool.
                </li>
                <li>
                  Enable seamless alert routing and incident management for
                  efficient incident response.
                </li>
                <li>
                  Receive timely alerts, escalate incidents, and collaborate
                  effectively to resolve issues faster.
                </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title: "How to create alerts in Pagerduty ?",
              desc: (
                <div>
                  You can refer{" "}
                  <a
                    href="https://docs.middleware.io/workflow/alerting/setup-alert"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Creating alert with Middleware
                  </a>{" "}
                  to get more idea on this.
                </div>
              ),
            },
          ],

          docLink:
            "https://docs.middleware.io/integrations/pagerduty-integration",
        },
      },
      collaborationProps: {
        title: CollaborationTitle.PagerDuty,
        description: `Enter the API key below Input box and click on button below to
      install
      the Middleware app into your Pagerduty workspace`,
        fields: [
          {
            key: "access_token",
          },
        ],
      },
    },
    {
      label: "Slack",
      link: IntegrationAppKey.Slack,
      prefixicon: <SlackIcon />,
      category: ["All Integration", "Collaboration"],
      integrationProps: {
        configKey: IntegrationConfigKey.Slack,
        shortDesc:
          "Slack is a hosted, fully-searchable communication platform that brings all of your team's communication into one place.",
        bgColor: `${themeMode === "dark" ? "rgba(46, 182, 125, 0.5)" : "rgba(236, 178, 46, 0.1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/collaboration"
            )
        ),
        overview: {
          description:
            "The Slack integration allows you to create and assign tickets to members within your organization. Create custom alerts and receive notifications in any channel within your Slack account.",
          image: ["assets/images/slack-integration.png"],
          integrationPoints: (
            <>
              <ul>
                <li>
                  Receive real-time notifications so you can quickly respond to
                  important events.
                </li>
                <li>Choose your preferred communication platform.</li>
                <li>
                  Centralize your communications and streamline your workflows.
                </li>
                <li>Customize your notification settings.</li>
                <li>Automate your alerts.</li>
              </ul>
            </>
          ),
          faqs: [
            {
              title: "Does it work in private channels?",
              desc: "Yes, it does allow to integrate with your private channels",
            },
          ],

          docLink: "https://docs.middleware.io/integrations/slack-integration",
        },
      },
      collaborationProps: {
        title: CollaborationTitle.Slack,
        description: `Click the button to integrate Middleware into your Slack workspace.`,
        fields: [],
      },
    },
    {
      label: "PostgreSQL",
      link: IntegrationAppKey.PostgreSQL,
      prefixicon: <PostgreSQLIcon />,
      category: ["All Integration", "Database"],
      integrationProps: {
        configKey: IntegrationConfigKey.Postgres,
        shortDesc:
          "PostgreSQL is an open-source object-relational database management system (ORDBMS).",
        bgColor: `${themeMode === "dark" ? "rgba(46, 182, 125, 0.5)" : "rgba(51, 103, 145, 0.1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/db.form.setting"
            )
        ),
        overview: {
          description:
            "The PostgreSQL Integration allows users to effortlessly ingest and monitor their object-relational database management system (ORDBMS) data. Collect PostgreSQL performance and health metrics from a PostgreSQL database.",
          image: ["assets/images/postgres_overview.png"],
          integrationPoints: (
            <>
              <ul>
                <li>
                  Collect performance and health metrics for your PostgreSQL
                  Database.
                </li>
                <li>
                  Generate graphs to understand the behavior of database
                  resource usage over time.
                </li>
                <li>
                  Relate if your database is affecting the overall resource of
                  your host.
                </li>
                <li>
                  Create alerts based on these metrics to monitor any unusual
                  metrics performance.
                </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "What are the prerequisites for using this PostgreSQL integration?",
              desc: "You need a PostgreSQL database, network access to the database, and appropriate permissions to collect metrics.",
            },
          ],

          supportedOs: ["Windows", "Linux", "Mac"],
          docLink:
            "https://docs.middleware.io/integrations/postgresql-integration",
        },
        columns: databaseTableCols,
        resourceType: "postgresql",
      },
    },
    {
      label: "MongoDB",
      link: IntegrationAppKey.MongoDB,
      prefixicon: <MongoDBIcon />,
      category: ["All Integration", "Database"],
      integrationProps: {
        configKey: IntegrationConfigKey.MongoDb,
        shortDesc:
          "MongoDB is a cross-platform and source-available NoSQL database.",
        bgColor: `${themeMode === "dark" ? "rgba(6, 172, 56, 0.5)" : "rgba(16, 170, 80, 0.1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/db.form.setting"
            )
        ),
        overview: {
          description:
            "The MongoDB Integration allows users to effortlessly ingest and monitor their NoSQL data. Collect MongoDB performance and health metrics.",
          image: ["assets/images/mongoDbOverview.png"],
          integrationPoints: (
            <>
              <ul>
                <li>Create and update issues using @jira mentions in</li>
                <li> monitor notifications Receive a Datadog event each </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "What are the prerequisites for using this MongoDB integration?",
              desc: "You need a MongoDB database, network access to the database, and appropriate permissions to collect metrics.",
            },
          ],

          supportedOs: ["Windows", "Linux", "Mac"],
          docLink:
            "https://docs.middleware.io/integrations/mongodb-integration",
        },

        columns: databaseTableCols,
        resourceType: "mongodb",
      },
    },
    {
      label: "MySQL",
      link: IntegrationAppKey.MySQL,
      prefixicon: <MySQLIcon />,
      category: ["All Integration", "Database"],
      integrationProps: {
        configKey: IntegrationConfigKey.MySQL,
        shortDesc:
          "MySQL is an open-source relational database management system (RDBMS).",
        bgColor: `${themeMode === "dark" ? "rgba(38, 132, 255, 0.5)" : "rgba(0, 117, 143, 0.1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/db.form.setting"
            )
        ),
        overview: {
          description:
            "The MySQL Integration allows users to effortlessly ingest and monitor their relational database management system (RDBMS) data. Collect MySQL performance and health metrics from a MySQL database.",
          image: ["assets/images/mySqlDbOverviewV2.png"],
          integrationPoints: (
            <>
              <ul>
                <li>
                  Capture and monitor database performance metrics to ensure
                  optimal efficiency.
                </li>
                <li>
                  See an overview of DB objects such as schemes, tables, and
                  indexes amongst others.
                </li>
                <li>
                  View key SQL events and aggregates in the form of charts and
                  tables.
                </li>
                <li>
                  Monitor database operations, such as insert, delete, update,
                  and read, and database I/O counts and wait times.
                </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "What are the prerequisites for using this MySQL integration?",
              desc: "You need a MySQL database, network access to the database, and appropriate permissions to collect metrics.",
            },
          ],

          supportedOs: ["Windows", "Linux", "Mac"],
          docLink: "https://docs.middleware.io/integrations/mysql-integration",
        },

        columns: databaseTableCols,
        resourceType: "mysql",
      },
    },
    {
      label: "MariaDB",
      link: IntegrationAppKey.MariaDB,
      prefixicon: <MariadbIcon />,
      category: ["All Integration", "Database"],
      integrationProps: {
        configKey: IntegrationConfigKey.MariaDB,
        shortDesc:
          "MariaDB is an open-source fork of MySQL, offering high compatibility with enhanced features and performance.",
        bgColor: `${themeMode === "dark" ? "rgba(165, 103, 41, 0.8)" : "rgba(0, 117, 143, 0.1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/db.form.setting"
            )
        ),
        overview: {
          description:
            "The MariaDB Integration allows users to effortlessly ingest and monitor their relational database management system (RDBMS) data. Collect MariaDB performance and health metrics from a MariaDB database.",
          image: ["assets/images/mySqlDbOverviewV2.png"],
          integrationPoints: (
            <>
              <ul>
                <li>
                  Capture and monitor database performance metrics to ensure
                  optimal efficiency.
                </li>
                <li>
                  See an overview of DB objects such as schemes, tables, and
                  indexes amongst others.
                </li>
                <li>
                  View key SQL events and aggregates in the form of charts and
                  tables.
                </li>
                <li>
                  Monitor database operations, such as insert, delete, update,
                  and read, and database I/O counts and wait times.
                </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "What are the prerequisites for using this Mariadb integration?",
              desc: "You need a Mariadb database, network access to the database, appropriate permissions to collect metrics and optionally performance schema enabled in your mariadb server.",
            },
          ],

          supportedOs: ["Windows", "Linux", "Mac"],
          docLink: "https://docs.middleware.io/integrations/mysql-integration",
        },

        columns: databaseTableCols,
        resourceType: "mysql",
      },
    },
    {
      label: "Redis",
      link: IntegrationAppKey.Redis,
      prefixicon: <RedisIcon />,
      category: ["All Integration", "Database"],
      integrationProps: {
        configKey: IntegrationConfigKey.Redis,
        shortDesc: "Redis is an open-source in-memory data structure store.",
        bgColor: `${themeMode === "dark" ? "rgba(220, 56, 44, 0.5)" : "rgba(220, 56, 44, 0.1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/db.form.setting"
            )
        ),
        overview: {
          description:
            "Retrieve data and metrics from a Redis instance to effectively monitor the performance of your Redis server.",
          image: ["assets/images/redis_overview.png"],
          integrationPoints: (
            <>
              <ul>
                <li>
                  Gain a clear snapshot of your Redis keyspace, lookups,
                  connections, clients, and command statistics.
                </li>
                <li>
                  Better understand your Redis instance using data collected by
                  the Middleware agent (Redis INFO data).
                </li>
                <li>
                  Monitor the uptime of your instance and recent RDB changes
                  since the last save.
                </li>
                <li>
                  Assess memory allocation, usage, and other metrics in bytes
                  across hosts.
                </li>
                <li>
                  Alert on TTL (Time To Live) for your Redis keys, revealing key
                  expiration insights.
                </li>
                <li>
                  Use time series charts that reveal usage counts for all Redis
                  commands.
                </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "What are the prerequisites for using this Redis integration?",
              desc: "You need a Redis database, network access to the database, and appropriate permissions to collect metrics.",
            },
          ],

          supportedOs: ["Windows", "Linux", "Mac"],
          docLink: "https://docs.middleware.io/integrations/redis-integration",
        },

        columns: databaseTableCols,
        resourceType: "redis",
      },
    },
    {
      label: "Clickhouse",
      link: IntegrationAppKey.Clickhouse,
      prefixicon: <ClickHouseIcon />,
      category: ["All Integration", "Database"],
      integrationProps: {
        configKey: IntegrationConfigKey.Clickhouse,
        shortDesc: "ClickHouse is a column-oriented database.",
        bgColor: `${themeMode === "dark" ? "rgba(255, 70, 73, 0.5)" : "rgba(255, 204, 0, 0.1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/db.form.setting"
            )
        ),
        overview: {
          description:
            "The ClickHouse Integration allows users to effortlessly ingest and monitor their column-oriented ClickHouse data. Use our default dashboard to comprehend system-wide usage patterns and identify performance bottlenecks in your ClickHouse cluster. Understand how your high-volume SQL queries perform in real-time to maximize your infrastructure’s efficiency further.",
          image: ["assets/images/clickhouse_overview.png"],
          integrationPoints: (
            <>
              <ul>
                <li>
                  Active Queries: Get the current query workload and optimize
                  processing efficiency.
                </li>
                <li>
                  Merges: Monitor ongoing background merges to improve database
                  performance.
                </li>
                <li>Memory: Track memory usage to ensure system stability.</li>
                <li>Network Activity: Track threads managing network data.</li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "What are the prerequisites for using this Clickhouse integration?",
              desc: "You need an Clickhouse database, network access to the database, and appropriate permissions to collect metrics.",
            },
          ],

          supportedOs: ["Windows", "Linux", "Mac"],
          docLink:
            "https://docs.middleware.io/integrations/clickhouse-integration",
        },

        columns: databaseTableCols,
        resourceType: "clickhouse",
      },
    },
    {
      label: "Cassandra",
      link: IntegrationAppKey.Cassandra,
      prefixicon: <CassandraIcon />,
      category: ["All Integration", "Database"],
      integrationProps: {
        configKey: IntegrationConfigKey.Cassandra,
        shortDesc: "Cassandra is an open-source NoSQL database.",
        bgColor: `rgba(187, 230, 251, 0.3)`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/db.form.setting"
            )
        ),
        overview: {
          description:
            "The Cassandra Database Integration provides seamless access to performance metrics and enables users to effortlessly visualize crucial insights in a single dashboard. The integration works by pulling metrics from the database through the Middleware Host Agent.",
          image: ["assets/images/cassandra_overview.png"],
          integrationPoints: (
            <>
              <ul>
                <li>
                  Caching Info: Monitor metrics like cache requests, latency,
                  and capacity.
                </li>
                <li>
                  Buffer Pool: Helps to maintain the optimal buffer pool size.
                </li>
                <li>
                  Resource Usage: Monitor resource-based metrics such as CPU,
                  memory, network, and disk information.
                </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "What are the prerequisites for using this Cassandra integration?",
              desc: "You need a Cassandra database, network access to the database, and appropriate permissions to collect metrics.",
            },
          ],

          supportedOs: ["Windows", "Linux", "Mac"],
          docLink:
            "https://docs.middleware.io/integrations/cassandra-integration",
        },
        releaseNotes: [
          {
            title: "Nov 27, 2023",
            note: "11/27/2023",
            changes: ["Added JMX receiver"],
            add: ["JMX receiver"],
          },
        ],
        columns: databaseTableCols,
        resourceType: "cassandra",
      },
    },
    {
      label: "Elasticsearch",
      link: IntegrationAppKey.ElasticSearch,
      prefixicon: <ElasticSearchIcon />,
      category: ["All Integration", "Database"],
      integrationProps: {
        configKey: IntegrationConfigKey.ElasticSearch,
        shortDesc:
          "Elasticsearch is a distributed, RESTful search and analytics engine that sits at the center of the Elastic Stack.",
        bgColor: `${themeMode === "dark" ? "rgba(60, 190, 177, 0.5)" : "rgba(0, 169, 229,0.1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/db.form.setting"
            )
        ),
        overview: {
          description:
            "The Elasticsearch Integration allows users to track their Elasticsearch performance and health metrics from Elasticsearch clusters and nodes. Use our default dashboard to understand system-wide usage patterns and identify performance bottlenecks. Drill into all of your data within the Elastic ecosystem.",
          image: ["assets/images/elastic_overview.png"],
          integrationPoints: (
            <>
              <ul>
                {" "}
                <li>
                  Memory usage: to prevent excessive memory consumption
                </li>{" "}
                <li>
                  Manage Indices: for efficient index and shared resource
                  allocation.
                </li>{" "}
                <li>
                  Disk usage: to manage disk space and improve I/O operations.
                </li>{" "}
                <li>Operational insights to manage operation efficiency</li>{" "}
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "What are the prerequisites for using this Elasticsearch integration?",
              desc: "You need an Elasticsearch database, network access to the database, and appropriate permissions to collect metrics.",
            },
          ],

          supportedOs: ["Windows", "Linux", "Mac"],
          docLink:
            "https://docs.middleware.io/integrations/elasticsearch-integration",
        },
        columns: databaseTableCols,
        resourceType: "elasticsearch",
      },
    },
    {
      label: "Prometheus",
      link: IntegrationAppKey.Prometheus,
      prefixicon: <PrometheusIcon />,
      category: ["All Integration", "Telemetry"],
      integrationProps: {
        configKey: IntegrationConfigKey.Prometheus,
        shortDesc:
          "Prometheus is an open-source tool used for monitoring and alerting.",
        bgColor: `${themeMode === "dark" ? "rgba(199, 70, 52, 0.5)" : "rgba(230, 82, 44, 0.1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/db.form.setting"
            )
        ),
        overview: {
          description:
            "The Prometheus Integration offers effortless access to performance metrics, allowing users to visualize key insights in our dashboard. Retrieve metrics from a Prometheus endpoint.",
          image: [""],
          integrationPoints: (
            <>
              <ul>
                <li>Create and update issues using @jira mentions in</li>
                <li> monitor notifications Receive a Datadog event each </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "What are the prerequisites for using this Clickhouse integration?",
              desc: "You should be having a publicly accessible prometheus endpoint from where Middleware agent can scrape the metrics",
            },
          ],

          supportedOs: ["Windows", "Linux", "Mac"],
          docLink:
            "https://docs.middleware.io/integrations/prometheus-integration",
        },
      },
    },
    {
      label: "Redpanda",
      link: IntegrationAppKey.Redpanda,
      prefixicon: <RedPandaIcon />,
      category: ["All Integration", "Streaming"],
      integrationProps: {
        configKey: IntegrationConfigKey.Redpanda,
        shortDesc: "Redpanda is a Kafka-compatible data streaming platform.",
        bgColor: `${themeMode === "dark" ? "rgba(242, 135, 6, 0.5)" : "rgba(222, 66, 38, 0.1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/db.form.setting"
            )
        ),
        overview: {
          description:
            "The Redpanda Integration offers effortless access to performance metrics, allowing users to visualize key insights in our dashboard. Identify bottlenecks swiftly and optimize Redpanda’s performance seamlessly.",
          image: ["assets/images/redpanda_overview.png"],
          integrationPoints: (
            <>
              <ul>
                <li>
                  Health and Usage: Monitor resources of RedPanda cluster.
                </li>
                <li>Raft Metrics: Monitor node status information.</li>
                <li>
                  Service Metrics: Understand the latency values with respect to
                  threshold.
                </li>
                <li>
                  Partition Metrics: Check the offset values and custom logs
                  performance.
                </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title: "How can Redpanda Integration help me ?",
              desc: "This integration helps you keep your data pipelines running smoothly by providing real-time performance metrics and insights, allowing you to optimize resources and manage cluster load effectively.",
            },
          ],

          supportedOs: ["Windows", "Linux", "Mac"],
          docLink:
            "https://docs.middleware.io/integrations/redpanda-integration",
        },
      },
    },
    {
      label: "Kafka",
      link: IntegrationAppKey.Kafka,
      prefixicon: <KafkaIcon />,
      category: ["All Integration", "Streaming"],
      integrationProps: {
        configKey: IntegrationConfigKey.Kafka,
        shortDesc: "Collects Kafka Performance and Health Metrics",
        bgColor: `${themeMode === "dark" ? "rgba(173, 173, 173, 0.5)" : "rgba(35, 31, 32, 0.1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/db.form.setting"
            )
        ),
        overview: {
          description:
            "The Kafka Integration offers real-time insights into your Kafka pipeline performance. Actively monitor your Kafka brokers, partitions, consumer groups, and JMX metrics in our default dashboard or with custom widgets.",
          image: [""],
          integrationPoints: (
            <>
              <ul>
                <li>
                  Broker Stats: It can tell you about the health of your broker
                  and also about active brokers that are handling the data
                </li>
                <li>
                  Partitions: You can understand how the current state of your
                  Kafka partitions. i.e. Offset, replicas, etc.
                </li>
                <li>
                  Consumer Groups: Keeping a watch on consumer groups can help
                  you to re-configure grouping for faster data transfer
                </li>
                <li>
                  JMX based metrics: This integration will also give you metrics
                  from "Java Management Extensions"
                </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title: "How can Kafka Integration help me ?",
              desc: "This integration ensures your Kafka pipelines run efficiently by offering real-time performance metrics and insights, enabling you to balance consumer load and improve throughput.",
            },
          ],

          supportedOs: ["Windows", "Linux", "Mac"],
          docLink: "https://docs.middleware.io/integrations/kafka-integration",
        },
      },
    },
    {
      label: "Oracle",
      link: IntegrationAppKey.OracleDB,
      prefixicon: <OracleIcon />,
      category: ["All Integration", "Database"],
      integrationProps: {
        configKey: IntegrationConfigKey.OracleDB,
        shortDesc: "Collects OracleDB performance and health metrics.",
        bgColor: `${themeMode === "dark" ? "rgba(237, 47, 35, 0.5)" : "rgba(237, 47, 35, 0.1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/db.form.setting"
            )
        ),
        overview: {
          description:
            "OracleDB is a multi-model database management system created by Oracle Corporation.",
          image: ["assets/images/apache-mw.png"],
          integrationPoints: (
            <>
              <ul>
                <li>Create and update issues using @jira mentions in</li>
                <li> monitor notifications Receive a Datadog event each </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "What are the prerequisites for using this Oracle integration?",
              desc: "You need an Oracle database, network access to the database, and appropriate permissions to collect metrics.",
            },
          ],

          supportedOs: ["Windows", "Linux", "Mac"],
          docLink:
            "https://docs.middleware.io/integrations/oracledb-integration",
        },
      },
    },
    {
      label: "Apache",
      link: IntegrationAppKey.Apache,
      prefixicon: <ApacheIcon />,
      category: ["All Integration", "Networking"],
      integrationProps: {
        configKey: IntegrationConfigKey.Apache,
        shortDesc: "Collects Apache performance and health metrics.",
        bgColor: `${themeMode === "dark" ? "rgba(237, 47, 35, 0.5)" : "rgba(190, 32, 46, ${borderOpacity})"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/common/db.form.setting"
            )
        ),
        overview: {
          description:
            "The Apache Network Integration provides real-time visibility into your team’s server performance and statistics.",
          image: ["assets/images/apache-mw.png"],
          integrationPoints: <></>,
          faqs: [
            {
              title:
                "What are the prerequisites for using this Apache integration?",
              desc: (
                <div>
                  You will need access to an Apache server, and you will have to{" "}
                  <a
                    href="https://docs.middleware.io/integrations/apache-server-integration#step-1-enable-status-support"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Enable Status support
                  </a>{" "}
                </div>
              ),
            },
          ],

          supportedOs: ["Windows", "Linux", "Mac"],
          docLink:
            "https://docs.middleware.io/integrations/apache-server-integration",
        },

        columns: databaseTableCols,
        resourceType: "apache",
      },
    },
    {
      label: "Amazon Web Services",
      link: IntegrationAppKey.AWS,
      prefixicon: <AwsIcon />,
      category: ["All Integration", "AWS"],
      integrationProps: {
        configKey: IntegrationConfigKey.AWS,
        shortDesc:
          "Collection of web services that together make up a cloud computing platform.",
        bgColor: `${themeMode === "dark" ? "rgb(96, 57, 0)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () => import("views/modules/installation-v2/pages/integrations/aws")
        ),
        overview: {
          description:
            "Amazon Web Services (AWS) is a collection of web services that together make up a cloud computing platform.",
          integrationPoints: (
            <>
              <ul>
                <li>Kinesis Stream</li>
                <li>API Polling </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "Do I need to pay any extra amount to AWS when I do this Integration ?",
              desc: "Depends on the Installation apporach. In the API Polling approach you do not have to pay any extra amount to AWS. For Kinesis stream, We will create Cloudwatch and Firehose streams in your AWS account, which may incur some charges",
            },
          ],
          docLink: "https://docs.middleware.io/integrations/aws-integration",
        },
        /*releaseNotes: [
          {
            title: "May 8, 2024",
            note: "5.3.1 / 2024-08-08 / Agent 7.53.0",
            add: ["Added options for users"],
            changes: ["Added options for users"],
            fixes: ["Added options for users"],
          },
        ],*/
        columns: awsResourceTableCols,
        resourceType: "AWS",
      },
    },
    {
      label: "Amazon EC2",
      link: IntegrationAppKey.AWSEc2,
      prefixicon: <AwsEc2Icon />,
      category: ["All Integration", "AWS"],
      integrationProps: {
        configKey: IntegrationConfigKey.AWSEc2,
        shortDesc:
          "Amazon Elastic Compute Cloud (Amazon EC2) is a web service that provides secure, resizable compute capacity in the cloud.",
        bgColor: `${themeMode === "dark" ? "rgba(239, 132, 8, 0.5)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/aws/resource"
            )
        ),
        overview: {
          description:
            "Amazon Elastic Compute Cloud (Amazon EC2) is a web service that provides secure, resizable compute capacity in the cloud.",
          integrationPoints: (
            <>
              <ul>
                <li>Kinesis Stream</li>
                <li>API Polling </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "Do I need to pay any extra amount to AWS when I do this Integration ?",
              desc: "Depends on the Installation apporach. In the API Polling approach you do not have to pay any extra amount to AWS. For Kinesis stream, We will create Cloudwatch and Firehose streams in your AWS account, which may incur some charges",
            },
          ],
          docLink: "https://docs.middleware.io/integrations/aws-integration",
        },
        /*releaseNotes: [
          {
            title: "May 8, 2024",
            note: "5.3.1 / 2024-08-08 / Agent 7.53.0",
            add: ["Added options for users"],
            changes: ["Added options for users"],
            fixes: ["Added options for users"],
          },
        ],*/
        columns: awsResourceTableCols,
        resourceType: "AWS/EC2",
      },
    },
    {
      label: "Amazon S3",
      link: IntegrationAppKey.AWSS3,
      prefixicon: <AwsS3Icon />,
      category: ["All Integration", "AWS"],
      integrationProps: {
        configKey: IntegrationConfigKey.AWSS3,
        shortDesc:
          "Amazon S3 (Simple Storage Service) is scalable, secure cloud storage for data backup, archiving, and hosting.",
        bgColor: `${themeMode === "dark" ? "rgba(77, 146, 44, 0.5)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/aws/resource"
            )
        ),
        overview: {
          description:
            "Amazon S3 (Simple Storage Service) is scalable, secure cloud storage for data backup, archiving, and hosting.",
          integrationPoints: (
            <>
              <ul>
                <li>Kinesis Stream</li>
                <li>API Polling </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "Do I need to pay any extra amount to AWS when I do this Integration ?",
              desc: "Depends on the Installation apporach. In the API Polling approach you do not have to pay any extra amount to AWS. For Kinesis stream, We will create Cloudwatch and Firehose streams in your AWS account, which may incur some charges",
            },
          ],
          docLink: "https://docs.middleware.io/integrations/aws-integration",
        },
        /*releaseNotes: [
          {
            title: "May 8, 2024",
            note: "5.3.1 / 2024-08-08 / Agent 7.53.0",
            add: ["Added options for users"],
            changes: ["Added options for users"],
            fixes: ["Added options for users"],
          },
        ],*/
        columns: awsResourceTableCols,
        resourceType: "AWS/S3",
      },
    },
    {
      label: "Amazon RDS",
      link: IntegrationAppKey.AWSRds,
      prefixicon: <AwsRdsIcon />,
      category: ["All Integration", "AWS"],
      integrationProps: {
        configKey: IntegrationConfigKey.AWSRds,
        shortDesc:
          "Amazon RDS (Relational Database Service) simplifies database setup, operation, and scaling in the cloud.",
        bgColor: `${themeMode === "dark" ? "rgba(53, 152, 130, 0.5)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/aws/resource"
            )
        ),
        overview: {
          description:
            "Amazon RDS (Relational Database Service) simplifies database setup, operation, and scaling in the cloud.",
          integrationPoints: (
            <>
              <ul>
                <li>Kinesis Stream</li>
                <li>API Polling </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "Do I need to pay any extra amount to AWS when I do this Integration ?",
              desc: "Depends on the Installation apporach. In the API Polling approach you do not have to pay any extra amount to AWS. For Kinesis stream, We will create Cloudwatch and Firehose streams in your AWS account, which may incur some charges",
            },
          ],
          docLink: "https://docs.middleware.io/integrations/aws-integration",
        },
        /*releaseNotes: [
          {
            title: "May 8, 2024",
            note: "5.3.1 / 2024-08-08 / Agent 7.53.0",
            add: ["Added options for users"],
            changes: ["Added options for users"],
            fixes: ["Added options for users"],
          },
        ],*/
        columns: awsResourceTableCols,
        resourceType: "AWS/RDS",
      },
    },
    {
      label: "Amazon ECS",
      link: IntegrationAppKey.AWSEcs,
      prefixicon: <AwsEcsIcon />,
      category: ["All Integration", "AWS"],
      integrationProps: {
        configKey: IntegrationConfigKey.AWSEcs,
        shortDesc:
          "Amazon Elastic Container Service (Amazon ECS)  is a fully managed solution for containerized application management.",
        bgColor: `${themeMode === "dark" ? "rgba(239, 132, 8, 0.5)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/aws/resource"
            )
        ),
        overview: {
          description:
            "Amazon Elastic Container Service (Amazon ECS) is a  is a fully managed solution for containerized application management.",
          integrationPoints: (
            <>
              <ul>
                <li>Kinesis Stream</li>
                <li>API Polling </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "Do I need to pay any extra amount to AWS when I do this Integration ?",
              desc: "Depends on the Installation apporach. In the API Polling approach you do not have to pay any extra amount to AWS. For Kinesis stream, We will create Cloudwatch and Firehose streams in your AWS account, which may incur some charges",
            },
          ],
          docLink: "https://docs.middleware.io/integrations/aws-integration",
        },
        /*releaseNotes: [
          {
            title: "May 8, 2024",
            note: "5.3.1 / 2024-08-08 / Agent 7.53.0",
            add: ["Added options for users"],
            changes: ["Added options for users"],
            fixes: ["Added options for users"],
          },
        ],*/
        columns: awsResourceTableCols,
        resourceType: "AWS/ECS",
      },
    },
    {
      label: "Amazon EBS",
      link: IntegrationAppKey.AWSEbs,
      prefixicon: <AwsEbsIcon />,
      category: ["All Integration", "AWS"],
      integrationProps: {
        configKey: IntegrationConfigKey.AWSEbs,
        shortDesc:
          "Amazon Elastic Block Store (Amazon EBS) is a scalable, high-performance block-storage service designed for Amazon EC2.",
        bgColor: `${themeMode === "dark" ? "rgba(77, 146, 44, 0.5)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/aws/resource"
            )
        ),
        overview: {
          description:
            "Amazon Elastic Block Store (Amazon EBS) is a scalable, high-performance block-storage service designed for Amazon EC2.",
          integrationPoints: (
            <>
              <ul>
                <li>Kinesis Stream</li>
                <li>API Polling </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "Do I need to pay any extra amount to AWS when I do this Integration ?",
              desc: "Depends on the Installation apporach. In the API Polling approach you do not have to pay any extra amount to AWS. For Kinesis stream, We will create Cloudwatch and Firehose streams in your AWS account, which may incur some charges",
            },
          ],
          docLink: "https://docs.middleware.io/integrations/aws-integration",
        },
        /*releaseNotes: [
          {
            title: "May 8, 2024",
            note: "5.3.1 / 2024-08-08 / Agent 7.53.0",
            add: ["Added options for users"],
            changes: ["Added options for users"],
            fixes: ["Added options for users"],
          },
        ],*/
        columns: awsResourceTableCols,
        resourceType: "AWS/EBS",
      },
    },
    {
      label: "Amazon Firehose",
      link: IntegrationAppKey.AWSFirehose,
      prefixicon: <AwsFirehoseIcon />,
      category: ["All Integration", "AWS"],
      integrationProps: {
        configKey: IntegrationConfigKey.AWSFirehose,
        shortDesc:
          "Amazon Firehose is a fully managed service for real-time data streaming to S3, Redshift, and Elasticsearch.",
        bgColor: `${themeMode === "dark" ? "rgba(155, 97, 248, 0.5)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/aws/resource"
            )
        ),
        overview: {
          description:
            "Amazon Firehose is a fully managed service for real-time data streaming to S3, Redshift, and Elasticsearch.",
          integrationPoints: (
            <>
              <ul>
                <li>Kinesis Stream</li>
                <li>API Polling </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "Do I need to pay any extra amount to AWS when I do this Integration ?",
              desc: "Depends on the Installation apporach. In the API Polling approach you do not have to pay any extra amount to AWS. For Kinesis stream, We will create Cloudwatch and Firehose streams in your AWS account, which may incur some charges",
            },
          ],
          docLink: "https://docs.middleware.io/integrations/aws-integration",
        },
        /*releaseNotes: [
          {
            title: "May 8, 2024",
            note: "5.3.1 / 2024-08-08 / Agent 7.53.0",
            add: ["Added options for users"],
            changes: ["Added options for users"],
            fixes: ["Added options for users"],
          },
        ],*/
        columns: awsResourceTableCols,
        resourceType: "AWS/Firehose",
      },
    },
    {
      label: "Amazon Lambda",
      link: IntegrationAppKey.AWSLambda,
      prefixicon: <AwsLambdaIcon />,
      category: ["All Integration", "AWS"],
      integrationProps: {
        configKey: IntegrationConfigKey.AWSLambda,
        shortDesc:
          "AWS Lambda is an event-driven, serverless Function as a Service (FaaS) provided by Amazon as a part of AWS.",
        bgColor: `${themeMode === "dark" ? "rgba(239, 132, 8, 0.5)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/aws/resource"
            )
        ),
        overview: {
          description:
            "AWS Lambda is an event-driven, serverless Function as a Service (FaaS) provided by Amazon as a part of AWS.",
          integrationPoints: (
            <>
              <ul>
                <li>Kinesis Stream</li>
                <li>API Polling </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "Do I need to pay any extra amount to AWS when I do this Integration ?",
              desc: "Depends on the Installation apporach. In the API Polling approach you do not have to pay any extra amount to AWS. For Kinesis stream, We will create Cloudwatch and Firehose streams in your AWS account, which may incur some charges",
            },
          ],
          docLink: "https://docs.middleware.io/integrations/aws-integration",
        },
        /*releaseNotes: [
          {
            title: "May 8, 2024",
            note: "5.3.1 / 2024-08-08 / Agent 7.53.0",
            add: ["Added options for users"],
            changes: ["Added options for users"],
            fixes: ["Added options for users"],
          },
        ],*/
        columns: awsResourceTableCols,
        resourceType: "AWS/Lambda",
      },
    },
    {
      label: "Amazon WAF",
      link: IntegrationAppKey.AWSWaf,
      prefixicon: <AwsWafIcon />,
      category: ["All Integration", "AWS"],
      integrationProps: {
        configKey: IntegrationConfigKey.AWSWaf,
        shortDesc:
          "AWS WAF protects against common web exploits and bots that impact availability, security, and resource consumption.",
        bgColor: `${themeMode === "dark" ? "rgba(239, 132, 8, 0.5)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/aws/resource"
            )
        ),
        overview: {
          description:
            "AWS WAF protects against common web exploits and bots that impact availability, security, and resource consumption.",
          integrationPoints: (
            <>
              <ul>
                <li>Kinesis Stream</li>
                <li>API Polling </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "Do I need to pay any extra amount to AWS when I do this Integration ?",
              desc: "Depends on the Installation apporach. In the API Polling approach you do not have to pay any extra amount to AWS. For Kinesis stream, We will create Cloudwatch and Firehose streams in your AWS account, which may incur some charges",
            },
          ],
          docLink: "https://docs.middleware.io/integrations/aws-integration",
        },
        /*releaseNotes: [
          {
            title: "May 8, 2024",
            note: "5.3.1 / 2024-08-08 / Agent 7.53.0",
            add: ["Added options for users"],
            changes: ["Added options for users"],
            fixes: ["Added options for users"],
          },
        ],*/
        columns: awsResourceTableCols,
        resourceType: "AWS/WAF",
      },
    },
    {
      label: "Amazon Lex",
      link: IntegrationAppKey.AWSLex,
      prefixicon: <AwsLexIcon />,
      category: ["All Integration", "AWS"],
      integrationProps: {
        configKey: IntegrationConfigKey.AWSLex,
        shortDesc:
          "Amazon Lex is a service for building conversational interfaces into any application using voice and text.",
        bgColor: `${themeMode === "dark" ? "rgba(53, 152, 130, 0.5)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/aws/resource"
            )
        ),
        overview: {
          description:
            "Amazon Lex is a service for building conversational interfaces into any application using voice and text.",
          integrationPoints: (
            <>
              <ul>
                <li>Kinesis Stream</li>
                <li>API Polling </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "Do I need to pay any extra amount to AWS when I do this Integration ?",
              desc: "Depends on the Installation apporach. In the API Polling approach you do not have to pay any extra amount to AWS. For Kinesis stream, We will create Cloudwatch and Firehose streams in your AWS account, which may incur some charges",
            },
          ],
          docLink: "https://docs.middleware.io/integrations/aws-integration",
        },
        /*releaseNotes: [
          {
            title: "May 8, 2024",
            note: "5.3.1 / 2024-08-08 / Agent 7.53.0",
            add: ["Added options for users"],
            changes: ["Added options for users"],
            fixes: ["Added options for users"],
          },
        ],*/
        columns: awsResourceTableCols,
        resourceType: "AWS/Lex",
      },
    },
    {
      label: "Amazon Kinesis",
      link: IntegrationAppKey.AWSKinesis,
      prefixicon: <AwsKinesisIcon />,
      category: ["All Integration", "AWS"],
      integrationProps: {
        configKey: IntegrationConfigKey.AWSKinesis,
        shortDesc:
          "Amazon Kinesis cost-effectively processes and analyzes streaming data at any scale as a fully managed service.",
        bgColor: `${themeMode === "dark" ? "rgba(53, 152, 130, 0.5)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/aws/resource"
            )
        ),
        overview: {
          description:
            "Amazon Kinesis cost-effectively processes and analyzes streaming data at any scale as a fully managed service.",
          integrationPoints: (
            <>
              <ul>
                <li>Kinesis Stream</li>
                <li>API Polling </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "Do I need to pay any extra amount to AWS when I do this Integration ?",
              desc: "Depends on the Installation apporach. In the API Polling approach you do not have to pay any extra amount to AWS. For Kinesis stream, We will create Cloudwatch and Firehose streams in your AWS account, which may incur some charges",
            },
          ],
          docLink: "https://docs.middleware.io/integrations/aws-integration",
        },
        /*releaseNotes: [
          {
            title: "May 8, 2024",
            note: "5.3.1 / 2024-08-08 / Agent 7.53.0",
            add: ["Added options for users"],
            changes: ["Added options for users"],
            fixes: ["Added options for users"],
          },
        ],*/
        columns: awsResourceTableCols,
        resourceType: "AWS/Kinesis",
      },
    },
    {
      label: "Amazon ELB",
      link: IntegrationAppKey.AWSElb,
      prefixicon: <AwsElbIcon />,
      category: ["All Integration", "AWS"],
      integrationProps: {
        configKey: IntegrationConfigKey.AWSElb,
        shortDesc:
          "Amazon Elastic Load Balancing (ELB) distributes incoming traffic across multiple AWS and on-premises targets.",
        bgColor: `${themeMode === "dark" ? "rgba(53, 152, 130, 0.5)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/aws/resource"
            )
        ),
        overview: {
          description:
            "Amazon Elastic Load Balancing (ELB) distributes incoming traffic across multiple AWS and on-premises targets.",
          integrationPoints: (
            <>
              <ul>
                <li>Kinesis Stream</li>
                <li>API Polling </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "Do I need to pay any extra amount to AWS when I do this Integration ?",
              desc: "Depends on the Installation apporach. In the API Polling approach you do not have to pay any extra amount to AWS. For Kinesis stream, We will create Cloudwatch and Firehose streams in your AWS account, which may incur some charges",
            },
          ],
          docLink: "https://docs.middleware.io/integrations/aws-integration",
        },
        /*releaseNotes: [
          {
            title: "May 8, 2024",
            note: "5.3.1 / 2024-08-08 / Agent 7.53.0",
            add: ["Added options for users"],
            changes: ["Added options for users"],
            fixes: ["Added options for users"],
          },
        ],*/
        columns: awsResourceTableCols,
        resourceType: "AWS/ELB",
      },
    },
    {
      label: "Amazon SNS",
      link: IntegrationAppKey.AWSSns,
      prefixicon: <AwsSnsIcon />,
      category: ["All Integration", "AWS"],
      integrationProps: {
        configKey: IntegrationConfigKey.AWSSns,
        shortDesc:
          "Amazon SNS (Simple Notification Service) is a managed service that provides message delivery from publishers to subscribers.",
        bgColor: `${themeMode === "dark" ? "rgba(53, 152, 130, 0.5)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/aws/resource"
            )
        ),
        overview: {
          description:
            "Amazon SNS (Simple Notification Service) is a managed service that provides message delivery from publishers to subscribers.",
          integrationPoints: (
            <>
              <ul>
                <li>Kinesis Stream</li>
                <li>API Polling </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "Do I need to pay any extra amount to AWS when I do this Integration ?",
              desc: "Depends on the Installation apporach. In the API Polling approach you do not have to pay any extra amount to AWS. For Kinesis stream, We will create Cloudwatch and Firehose streams in your AWS account, which may incur some charges",
            },
          ],
          docLink: "https://docs.middleware.io/integrations/aws-integration",
        },
        /*releaseNotes: [
          {
            title: "May 8, 2024",
            note: "5.3.1 / 2024-08-08 / Agent 7.53.0",
            add: ["Added options for users"],
            changes: ["Added options for users"],
            fixes: ["Added options for users"],
          },
        ],*/
        columns: awsResourceTableCols,
        resourceType: "AWS/SNS",
      },
    },
    {
      label: "Amazon SQS",
      link: IntegrationAppKey.AWSSqs,
      prefixicon: <AwsSqsIcon />,
      category: ["All Integration", "AWS"],
      integrationProps: {
        configKey: IntegrationConfigKey.AWSSqs,
        shortDesc:
          "Amazon SQS lets you send, store, and receive messages between software components at any volume, without losing messages.",
        bgColor: `${themeMode === "dark" ? "rgba(53, 152, 130, 0.5)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/aws/resource"
            )
        ),
        overview: {
          description: "Amazon SQS...",
          integrationPoints: (
            <>
              <ul>
                <li>Kinesis Stream</li>
                <li>API Polling </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "Do I need to pay any extra amount to AWS when I do this Integration ?",
              desc: "Depends on the Installation apporach. In the API Polling approach you do not have to pay any extra amount to AWS. For Kinesis stream, We will create Cloudwatch and Firehose streams in your AWS account, which may incur some charges",
            },
          ],
          docLink: "https://docs.middleware.io/integrations/aws-integration",
        },
        /*releaseNotes: [
          {
            title: "May 8, 2024",
            note: "5.3.1 / 2024-08-08 / Agent 7.53.0",
            add: ["Added options for users"],
            changes: ["Added options for users"],
            fixes: ["Added options for users"],
          },
        ],*/
        columns: awsResourceTableCols,
        resourceType: "AWS/SQS",
      },
    },
    {
      label: "Amazon ElastiCache",
      link: IntegrationAppKey.AWSElasticache,
      prefixicon: <AwsElasticacheIcon />,
      category: ["All Integration", "AWS"],
      integrationProps: {
        configKey: IntegrationConfigKey.AWSElasticache,
        shortDesc:
          "Amazon ElastiCache is a managed in-memory caching service for real-time use cases, supporting Redis OSS and Memcached.",
        bgColor: `${themeMode === "dark" ? "rgba(53, 152, 130, 0.5)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/aws/resource"
            )
        ),
        overview: {
          description: "Amazon ElastiCache...",
          integrationPoints: (
            <>
              <ul>
                <li>Kinesis Stream</li>
                <li>API Polling </li>
              </ul>
            </>
          ),
          faqs: [
            {
              title:
                "Do I need to pay any extra amount to AWS when I do this Integration ?",
              desc: "Depends on the Installation apporach. In the API Polling approach you do not have to pay any extra amount to AWS. For Kinesis stream, We will create Cloudwatch and Firehose streams in your AWS account, which may incur some charges",
            },
          ],
          docLink: "https://docs.middleware.io/integrations/aws-integration",
        },
        /*releaseNotes: [
          {
            title: "May 8, 2024",
            note: "5.3.1 / 2024-08-08 / Agent 7.53.0",
            add: ["Added options for users"],
            changes: ["Added options for users"],
            fixes: ["Added options for users"],
          },
        ],*/
        columns: awsResourceTableCols,
        resourceType: "AWS/ElastiCache",
      },
    },
    {
      label: "OpenTelemetry Logs",
      link: IntegrationAppKey.OpenTelemetryLogs,
      prefixicon: <OpenTelemetry />,
      category: ["All Integration", "Data Ingestion APIs"],
      integrationProps: {
        configKey: IntegrationConfigKey.OpenTelemetryLogs,
        shortDesc:
          "OpenTelemetry Logs allow you to send and monitor custom log data in Middleware.",
        bgColor: `${themeMode === "dark" ? "rgb(96, 57, 0)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/opentelemetry-logs"
            )
        ),
        overview: {
          description:
            "More advanced users have the option to use OpenTelemetry Protocol Exporters instead of Middleware’s Agent and associated SDKs to ingest data. Each language will have a different approach which can be an adaptation from our relevant Host Agent.",
          docLink:
            "https://docs.middleware.io/data-ingestion-apis/opentelemetry-metrics",
          faqs: [
            {
              title: "How does Authentication work for this API ?",
              desc: "The API currently takes the authentication token from the mw.account_key , under resource_attributes",
            },
            {
              title:
                "Where can I find more detailed documentation on OpenTelemetry Logs Data Format ?",
              desc: "You can refer OpenTelemetry Logs Proto to understand more about OpenTelemetry Logs Data Format",
            },
          ],
        },
        resourceType: "OpenTelemetry Logs",
      },
    },
    {
      label: "OpenTelemetry Metrics",
      link: IntegrationAppKey.OpenTelemetryMetrics,
      prefixicon: <OpenTelemetry />,
      category: ["All Integration", "Data Ingestion APIs"],
      integrationProps: {
        configKey: IntegrationConfigKey.OpenTelemetryMetrics,
        shortDesc:
          "OpenTelemetry Metrics allow you to send and monitor custom Metric data in Middleware.",
        bgColor: `${themeMode === "dark" ? "rgb(96, 57, 0)" : "rgba(255, 248, 237, 1)"}`,
        component: React.lazy(
          () =>
            import(
              "views/modules/installation-v2/pages/integrations/opentelemetry-metrics"
            )
        ),
        overview: {
          description:
            "More advanced users have the option to use OpenTelemetry Protocol Exporters instead of Middleware’s Agent and associated SDKs to ingest data. Each language will have a different approach which can be an adaptation from our relevant Host Agent.",
          docLink:
            "https://docs.middleware.io/data-ingestion-apis/opentelemetry-metrics",
          faqs: [
            {
              title: "How does Authentication work for this API ?",
              desc: "The API currently takes the authentication token from the mw.account_key , under resource_attributes",
            },
            {
              title:
                "Where can I find more detailed documentation on OpenTelemetry Logs Data Format ?",
              desc: "You can refer OpenTelemetry Metrics Proto to understand more about OpenTelemetry Metrics Data Format",
            },
          ],
        },
        resourceType: "OpenTelemetry Metrics",
      },
    },
  ];
};
