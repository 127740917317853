import { APIStatus, isQueryTypeAvailable } from "core/application/utils";
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CancelToken } from "axios";
import CreateRequest from "core/xhr";
import { showToast } from "store/toast-alerts/actions";
import {
  layoutUpdates,
  makeRequestForAvailableMetrics,
  receivedAvailableMetrics,
  receivedAvailableMetricsAttributeValues,
  receivedAwsNamspaces,
  receivedBuilderFormattedData,
  receivedBuilderMetricFilterValues,
  receivedReportsBuilderList,
  receivedResourceList,
  receivedWidgetBuilderDialog,
  requestAwsNamspaces,
} from "store/widgets/actions";
import {
  AddUpdateWidgetBuilder,
  AttrValues,
  AvailableMetricsResponse,
  BuilderViewOptions,
  ChartType,
  CustomWidget,
  GridviewDataType,
  GroupByItem,
  MetricItem,
  MetricTypeEnum,
  Widget,
} from "views/modules/builder/entities/builder.entities";
import { MetricListQueryParams, MetricsAPIResp } from "./entities";
import { prepareReqBodyForAttributeSearch } from "./helpers";

export const requestBuilderScopeUpdate = (
  body: any,
  callback?: (status: boolean, res: any) => void
) => {
  return (dispatch: (a0: any) => void) => {
    callback && callback(true, { inflight: true });
    return CreateRequest({
      url: `/builder/widget/scope/layouts`,
      method: "PUT",
      data: { ...body },
    })
      .then((res) => {
        dispatch(layoutUpdates(body.layouts));
        callback && callback(false, { inflight: false, ...(res?.data || {}) });
      })
      .catch(() => {
        callback && callback(false, { inflight: false });
      });
  };
};

export const requestReportBuilderList = (
  params: any,
  callback?: (success: boolean, inflight: boolean, data?: Widget[]) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    dispatch(receivedReportsBuilderList({ inflight: true }));
    callback?.(true, true);
    return CreateRequest({
      url: `/builder/widget`,
      method: "GET",
      params,
    })
      .then((res) => {
        dispatch(receivedReportsBuilderList({ inflight: false }));
        callback?.(true, false);
        if (res.status !== 200 || !Array.isArray(res.data)) return;
        const builderViewOptions: BuilderViewOptions = {
          report: {
            reportId: params.report_id,
            reportName: params.report_label,
          },
          displayScope: params.display_scope,
        };

        dispatch(
          receivedReportsBuilderList({
            data: {
              items: res.data,
              builderViewOptions,
            },
            inflight: false,
          })
        );
        callback?.(true, false, res.data);
      })
      .catch((e) => {
        console.log("ReportBuilderList-Error---", e);
        dispatch(receivedReportsBuilderList({ items: [], inflight: false }));
      });
  };
};
// interface RequestBuilderFormattedData {
//   body: AddUpdateWidgetBuilder;
//   callback?: unknown;
//   noState?: boolean;
// }
// const queue: RequestBuilderFormattedData[] = [];
// let requestsCount = 0;
// const maxRequests = 5;
export const requestBuilderFormattedData = (
  body: AddUpdateWidgetBuilder,
  callback?: any,
  noState?: boolean,
  reqToken?: CancelToken
) => {
  return (dispatch: (arg0: any) => void) => {
    callback?.(true, { inflight: true });
    if (!noState && !callback) {
      body.inflight = true;
      if (body?.body?.widgetData) {
        delete body.body.widgetData;
      }
      body.body.isError = false;
      dispatch(receivedBuilderFormattedData(body));
    }
    const queryType = isQueryTypeAvailable()?.values?.[0] || "";
    if (queryType) {
      if (body.body.params) {
        const index = body.body.params.findIndex(
          (p: any) => p.key === "query_type"
        );
        if (index === -1) {
          body.body.params.push({ key: "query_type", value: queryType });
        }
      } else {
        body.body.params = [{ key: "query_type", value: queryType }];
      }
    }
    const q: BuilderViewOptions = body?.body?.builderViewOptions;
    let qString = "";
    if (q?.resource) {
      qString = "?req=resource=" + q.resource?.name;
    } else if (q?.report && body.body.builderId) {
      qString = "?req=builderId=" + body.body.builderId;
    }
    // if (requestsCount >= maxRequests) {
    //   queue.push({ body, callback, noState });
    //   return;
    // }
    // requestsCount++;
    return CreateRequest({
      url: `/builder/widget/data` + qString,
      method: "POST",
      data: typeof body == "object" ? { ...body.body } : {},
      cancelToken: reqToken,
    })
      .then((res) => {
        // // requestsCount--;
        // // if (queue.length > 0) {
        // //   const next = queue.shift();
        // //   if (next) {
        // //     dispatch(
        // //       requestBuilderFormattedData(
        // //         next.body,
        // //         next.callback,
        // //         next.noState
        // //       )
        // //     );
        // //   }
        // // }
        if (!noState && !callback) {
          const errorMsg =
            res.data.error &&
            typeof res.data.error === "string" &&
            res.data.error.includes("time range must be") &&
            res.data.error;

          if (res.status === 500) {
            dispatch(
              receivedBuilderFormattedData({
                ...body,
                inflight: false,
                body: {
                  ...body.body,
                  isError: true,
                  errorMsg: errorMsg,
                },
              })
            );
            callback?.(true, { inflight: false, body, ...res.data });
            return;
          }
          if (!res.data.status && res.data.error) {
            // dispatch(showToast("warning", (typeof res.data.error === "string" ? res.data.error : "Something went wrong!!"), { duration: 3000 }))
            dispatch(
              receivedBuilderFormattedData({
                ...body,
                inflight: false,
                body: {
                  ...body.body,
                  isError: true,
                  errorMsg: errorMsg,
                },
              })
            );
            callback?.(true, { inflight: false, body, ...res.data });
            return;
          }
          dispatch(
            receivedBuilderFormattedData({
              ...body,
              body: {
                ...body.body,
                widgetData: res.data.chart_data,
              },
              inflight: false,
            })
          );
        }
        callback?.(true, { inflight: false, body, ...res.data });
      })
      .catch((e) => {
        console.log("e---", e);
        // requestsCount--;
        // if (queue.length > 0) {
        //   const next = queue.shift();
        //   if (next) {
        //     dispatch(
        //       requestBuilderFormattedData(
        //         next.body,
        //         next.callback,
        //         next.noState
        //       )
        //     );
        //   }
        // }
        if (!noState) {
          dispatch(
            receivedBuilderFormattedData({
              ...body,
              inflight: false,
            })
          );
        }
        callback?.(false, { inflight: false });
      });
  };
};
export const requestBuilderData = (
  body: CustomWidget[],
  callback: (success: boolean, inflight: boolean, data?: any) => void,
  reqToken?: CancelToken
) => {
  const queryType = isQueryTypeAvailable()?.values?.[0] || "";
  if (queryType) {
    body = body.map((b) => {
      if (b.params) {
        const index = b.params.findIndex((p: any) => p.key === "query_type");
        if (index === -1) {
          b.params.push({ key: "query_type", value: queryType });
        }
      } else {
        b.params = [{ key: "query_type", value: queryType }];
      }
      return b;
    });
  }
  return () => {
    callback && callback(true, true);
    return CreateRequest({
      url: `/builder/widget/multi-data`,
      method: "POST",
      data: typeof body == "object" ? body : [],
      cancelToken: reqToken,
    })
      .then((res) => {
        if (res.status === 500) {
          callback && callback(false, false);
          return;
        }
        if (!res.data.status && res.data.error) {
          callback && callback(false, false);
          return;
        }
        callback && callback(true, false, res.data);
      })
      .catch((e) => {
        console.log("e---", e);
        callback && callback(false, false);
      });
  };
};
export const requestReportBuilderCreate = (
  body: any,
  callback?: (
    success: boolean,
    res: {
      inflight: boolean;
      widget?: Widget;
      error?: string;
    }
  ) => void
) => {
  return () => {
    callback && callback(true, { inflight: true });
    return CreateRequest({
      url: `/builder/widget`,
      method: "POST",
      data: body || {},
    })
      .then((res) => {
        // if (!res?.data?.error) {
        //     dispatch(requestReportBuilderList({ report_id: id, display_scope: body.displayScope }))
        // }
        if (res.status === 200) {
          callback && callback(true, { inflight: false, widget: res.data });
        } else {
          callback &&
            callback(false, { inflight: false, error: res.data.error });
        }
      })
      .catch((e) => {
        console.log("e---", e);
        callback && callback(false, { inflight: false });
      });
  };
};
let isApiCallled = false;
export const requestAvailableMetrics = () => {
  return (dispatch: (arg0: any) => void) => {
    if (isApiCallled) {
      return;
    }
    isApiCallled = true;
    dispatch(makeRequestForAvailableMetrics());
    return CreateRequest({
      url: `/builder/metrics`,
      method: "GET",
    })
      .then((res) => {
        if (res.status === 200) {
          const r: AvailableMetricsResponse = res.data;
          dispatch(receivedAvailableMetrics(r));
        } else {
          dispatch(receivedAvailableMetrics());
          // dispatch(showToast("warning", "Failed to get datasets", { duration: 3000 }))
        }
      })
      .catch((e) => {
        console.log("e---", e);
      });
  };
};
export const deleteBuilder = (
  builderId: number,
  callback?: (
    success: boolean,
    payload: {
      inflight: boolean;
    }
  ) => void
) => {
  return () => {
    callback && callback(true, { inflight: true });
    return CreateRequest({
      url: `/builder/widget/` + builderId,
      method: "DELETE",
    })
      .then((res) => {
        if (res.status === 200) {
          callback && callback(true, { inflight: false });
        } else {
          callback && callback(false, { inflight: false });
        }
      })
      .catch((e) => {
        console.log("e---", e);
        callback && callback(false, { inflight: false });
      });
  };
};
// to-do: @Bhomaram remove this
export const requestWidgetBuilderDialog = (opts: any, callback: any) => {
  const { display_scope, body } = opts;
  return (dispatch: (arg0: any) => void) => {
    callback?.(false, { inflight: false });
    return CreateRequest({
      url: `/widgets/builder-dialog/${display_scope}`,
      method: "POST",
      data: body,
    })
      .then((res) => {
        callback?.(true, { inflight: false, data: res.data });
        dispatch(
          receivedWidgetBuilderDialog({
            data: res?.data?.dialog,
          })
        );
        dispatch(
          showToast(
            res?.data?.dialog?.display_scope ? "success" : "error",
            res?.data?.dialog?.display_scope
              ? "Widget added successfully"
              : "Failed to update widget config",
            { duration: 3000 }
          )
        );
      })
      .catch((e) => {
        console.log("e---", e);
        callback?.(false, { inflight: false });
      });
  };
};
// to-do: @Bhomaram remove this
export const requestGetWidgetBuilderDialog = (scope: string) => {
  return (dispatch: (arg0: any) => void) => {
    dispatch(receivedWidgetBuilderDialog({ inflight: true }));
    return CreateRequest({
      url: `/widgets/builder-dialog/${scope}`,
      method: "GET",
    })
      .then((res) => {
        dispatch(
          receivedWidgetBuilderDialog({
            inflight: false,
            data: res?.data?.dialog?.display_scope ? res?.data?.dialog : null,
          })
        );
      })
      .catch((e) => {
        console.log("e---", e);
        dispatch(receivedWidgetBuilderDialog({ inflight: false }));
      });
  };
};
export const requestAwsNamespaces = (
  callback?: (res?: boolean, data?: string[]) => void
) => {
  return (dispatch: (arg0: any) => void) => {
    dispatch(requestAwsNamspaces());
    return CreateRequest({
      url: `/widgets/get-aws-namesspaces`,
      method: "GET",
    })
      .then((res) => {
        if (callback) {
          callback(res.data.status, res?.data?.namespaces);
        }
        if (res?.data?.namespaces) {
          dispatch(receivedAwsNamspaces(res?.data?.namespaces));
        }
      })
      .catch((e) => {
        console.log("e---", e);
        // dispatch(receivedWidgetBuilderDialog({ inflight: false }))
      });
  };
};

// getAttributeValues fetches the values of the given attribute that matches the given search criteria
export const getAttributeValues =
  (
    resource: string,
    attribute: string,
    attType: MetricTypeEnum,
    chartType: ChartType,
    fromTs: number,
    toTs: number,
    search?: string
  ) =>
  // getAttributeValues is an action creater function that returns a function which takes dispatch as a parameter
  // and performs tasks and dispatch actions asynchronously
  (dispatch: (action: unknown) => void) => {
    // Prepare the request body
    const reqBody = prepareReqBodyForAttributeSearch(
      resource,
      attribute,
      attType,
      fromTs,
      toTs,
      search
    );

    return CreateRequest({
      url: "/builder/widget/data",
      method: "POST",
      data: reqBody,
    })
      .then((res) => {
        if (res.status !== 200) {
          return;
        }

        dispatch(
          receivedAvailableMetricsAttributeValues(
            resource,
            attribute,
            chartType,
            res.data.chart_data
          )
        );
      })
      .catch((err) => {
        console.log("e---", err);
      });
  };

// fetchResourceList fetches the list of resources
export const fetchResourceList =
  () => async (dispatch: (arg: unknown) => void) => {
    dispatch(receivedResourceList({ apiStatus: APIStatus.LOADING }));

    try {
      const res = await CreateRequest({
        url: "/builder/resources",
        method: "GET",
      });

      if (res.status !== 200) {
        dispatch(
          receivedResourceList({
            apiStatus: APIStatus.ERROR,
            errorMsg: (res.data.error as string) || "Something went wrong!!",
          })
        );
        return;
      }

      dispatch(
        receivedResourceList({
          apiStatus: APIStatus.SUCCESS,
          resources: res.data as string[],
        })
      );
    } catch (e) {
      console.error("e---", e);

      dispatch(
        receivedResourceList({
          apiStatus: APIStatus.ERROR,
          errorMsg: e as string,
        })
      );
    }
  };

// fetchMetricList fetches the list of metrics/filters/groupbys that matches the given search criteria
export const fetchMetricList =
  (params: MetricListQueryParams, actionFnc: (resp: MetricsAPIResp) => void) =>
  async (dispatch: (arg: unknown) => void) => {
    const metricListResp: MetricsAPIResp = {
      apiStatus: APIStatus.LOADING,
    };

    dispatch(actionFnc(metricListResp));

    try {
      const res = await CreateRequest({
        url: "/builder/metrics-v2",
        method: "GET",
        params,
      });

      if (res.status !== 200) {
        metricListResp.apiStatus = APIStatus.ERROR;
        metricListResp.errorMsg =
          (res.data.error as string) || "Something went wrong!!";

        dispatch(actionFnc(metricListResp));
        return;
      }

      metricListResp.apiStatus = APIStatus.SUCCESS;
      metricListResp.page = res.data.page as number;
      metricListResp.limit = res.data.limit as number;
      metricListResp.items = res.data.items as
        | MetricItem[]
        | GroupByItem[]
        | AttrValues[];
    } catch (e) {
      console.error("e---", e);

      metricListResp.apiStatus = APIStatus.ERROR;
      metricListResp.errorMsg = e as string;
    }

    dispatch(actionFnc(metricListResp));
  };

// fetchFltAttrValSearchResult fetches the list of attribute values that matches the given search criteria
export const fetchBuilderFilterValues =
  (
    resource: string,
    attName: string,
    attLabel: string,
    attType: MetricTypeEnum,
    fromTs: number,
    toTs: number,
    search?: string
  ) =>
  async (dispatch: (arg: unknown) => void) => {
    dispatch(
      receivedBuilderMetricFilterValues({
        apiStatus: APIStatus.LOADING,
      })
    );

    // Prepare the request body
    const reqBody = prepareReqBodyForAttributeSearch(
      resource,
      attName,
      attType,
      fromTs,
      toTs,
      search
    );

    try {
      const res = await CreateRequest({
        url: "/builder/widget/data",
        method: "POST",
        data: reqBody,
      });

      if (res.status !== 200) {
        dispatch(
          receivedBuilderMetricFilterValues({
            apiStatus: APIStatus.ERROR,
            errorMsg: (res.data.error as string) || "Something went wrong!!",
          })
        );
        return;
      }

      // Get the list of search results from the response
      const searchResult: string[] = [];
      (res.data.chart_data as GridviewDataType).data.forEach((item) => {
        if (item[attName]) searchResult.push(item[attName] as string);
      });

      dispatch(
        receivedBuilderMetricFilterValues({
          apiStatus: APIStatus.SUCCESS,
          attName,
          attLabel,
          attType,
          resource,
          searchResult,
        })
      );
    } catch (e) {
      console.log("e---", e);

      dispatch(
        receivedBuilderMetricFilterValues({
          apiStatus: APIStatus.ERROR,
          errorMsg: e as string,
        })
      );
    }
  };
