import * as React from "react";
import { useEffect, useState } from "react";
import classNames from "classnames";
import {
  TabItemProps,
  TabItemsComponent,
} from "core/components/v2/nav-tab/entity";

interface Props {
  children: TabItemsComponent | TabItemsComponent[];
  onTabChange?: (index: number) => void;
  classname?: string;
  defaultActiveTabIndex?: number;
  targetIndex?: number;
  showBorderTop?: boolean;
  showBorderBottom?: boolean;
  extraElements?: React.ReactNode;
}

const NavTab = React.memo(function (props: Props) {
  const {
    targetIndex,
    children,
    showBorderTop,
    showBorderBottom,
    defaultActiveTabIndex,
    extraElements,
  } = props;

  const NavTabClass = classNames("tabs-nav nav navbar-nav navbar-left", {
    "show-border-top": showBorderTop,
    "show-border-bottom": showBorderBottom,
  });

  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    defaultActiveTabIndex ?? targetIndex ?? 0
  );

  useEffect(() => {
    if (typeof defaultActiveTabIndex !== "undefined") {
      handleTabClick(defaultActiveTabIndex);
    }
  }, [defaultActiveTabIndex]);

  useEffect(() => {
    if (typeof targetIndex !== "undefined") {
      setActiveTabIndex(targetIndex);
    }
  }, [targetIndex]);

  const handleTabClick = (tabIndex: number) => {
    props.onTabChange && props.onTabChange(tabIndex);
    setActiveTabIndex(tabIndex);
  };

  const renderTabsNav = () => {
    return React.Children.map(
      props.children,
      (child: React.ReactElement<TabItemProps>, index: number) => {
        if (
          child?.type &&
          (child.type as React.ComponentType<TabItemProps>).displayName ===
            "TabItems"
        ) {
          return React.cloneElement(child, {
            onClick: () => handleTabClick(index),
            tabIndex: index,
            isActive: index === activeTabIndex,
          });
        }
        return null;
      }
    );
  };

  const renderActiveTabContent = () => {
    if (
      Array.isArray(children) &&
      activeTabIndex !== undefined &&
      children[activeTabIndex]
    ) {
      return (
        children[activeTabIndex]?.props?.children && (
          <div className={`tabs-active-content tab_content__wrapper`}>
            {children[activeTabIndex]?.props?.children}
          </div>
        )
      );
    } else {
      const child = children as TabItemsComponent;
      return (
        child?.props?.children && (
          <div className={`tabs-active-content tab_content__wrapper`}>
            {child.props.children}
          </div>
        )
      );
    }
  };

  return (
    <div className={`component__nav_tabs__wrapper ${props?.classname || ""}`}>
      <div className={`tabs-list-container ${NavTabClass}`}>
        <ul className="tabs-list">{renderTabsNav()}</ul>
        {extraElements}
      </div>
      {renderActiveTabContent()}
    </div>
  );
});
NavTab.displayName = "NavTab";

export default NavTab;
