import { AwsEcsSvg } from "core/components/v2/svg/icons";
import {
  AgentIcon,
  AlmaLinuxIcon,
  AmazonLinuxOsIcon,
  ApmIcon,
  CentOsIcon,
  DatadogIcon,
  DebianIcon,
  DockerIcon,
  ElasticLogstashIcon,
  FedoraCoreOsIcon,
  FedoraOsIcon,
  FlatcarContainerLinuxIcon,
  IntegrationIcon,
  KubernetesIcon,
  LinuxIcon,
  OracleLinuxIcon,
  RedHatIcon,
  RockyLinuxIcon,
  RumIcon,
  SuseIcon,
  UbuntuIcon,
  WindowsIcon,
} from "core/components/v2/svg/integrations-icons";
import { ColumnProps } from "core/components/v2/table-view/entity";
import { getThemeState } from "core/utils";
import React from "react";
import FedoraCoreContainerIntegration from "views/modules/installation-v2/pages/agent/fedora-coreOS";
import FlatcarContainerIntegration from "views/modules/installation-v2/pages/agent/flatcar-container";
import { apmItems } from "./apm-entity";
import {
  CollaborationIntegrationProps,
  IntegrationItems,
} from "./integration-entity";
import AwsEcsIntegration from "./pages/agent/aws-ecs";
import DatadogIntegration from "./pages/agent/datadog";
import DockerIntegration from "./pages/agent/docker";
import ElasticLogStashIntegration from "./pages/agent/elastic-logstash";
import KubernetesIntegration from "./pages/agent/kubernetes";
import LinuxIntegration from "./pages/agent/linux";
import WindowsIntegration from "./pages/agent/windows";
import { RumItems } from "./rum-entity";
export const defaultInstallationPath = "/installation/agent/kubernetes";
export const defaultInstallationSlug = "/installation/agent/kubernetes";
export const installationQuery = "installation";

export interface InstallationSections {
  title: string;
  prefixicon: React.ReactNode;
  link: string;
  items: () => SectionItems[];
}
export interface SectionItems {
  label: string;
  link: string;
  prefixicon: React.ReactNode;
  category?: string[];
  pageProps?: InstallationPageProps;
  integrationProps?: IntegrationPageProps;
  collaborationProps?: CollaborationIntegrationProps;
  subItems?: SectionItems[];
  reqGetSetting?: () => void;
}

export interface ParamsDetail {
  index: number;
  link: string;
}
export interface InstallationPageProps {
  title: string;
  bgColor: string;
  bodyTitle?: string;
  bodyDesc?: React.ReactNode;
  component?: React.ReactNode;
  icon?: React.ReactNode;
  dashboardLink?: string;
  footerComponent?: React.ReactNode;
  docLink?: string;
}

export interface IntegrationPageProps {
  configKey: string;
  bgColor: string;
  shortDesc: string;
  component?: React.ReactNode;
  overview: OverviewProps;
  releaseNotes?: ReleaseNotesProps[];
  columns?: ColumnProps[];
  footerComponent?: React.ReactNode;
  resourceType?: string;
}
export interface ReleaseNotesProps {
  title: string;
  note: string;
  addLabel?: string;
  add?: string[];
  changes?: string[];
  fixes?: string[];
}
export interface OverviewProps {
  description: string;
  image?: string[];
  integrationPoints?: React.ReactNode;
  faqs?: FaqProps[];
  supportedOs?: string[];
  docLink?: string;
}
export interface FaqProps {
  title: string;
  desc: React.ReactNode | string;
}

export const AgentItems: () => SectionItems[] = () => {
  const themeMode = getThemeState();
  const borderOpacity = themeMode === "dark" ? 0.4 : 0.1;
  return [
    {
      label: "Kubernetes",
      link: "kubernetes",
      prefixicon: <KubernetesIcon />,
      pageProps: {
        title: "Kubernetes",
        bgColor: `rgba(245, 248, 254, ${themeMode === "dark" ? 0.4 : 1})`,
        bodyTitle: "Installing Middleware Agent on Kubernetes",
        docLink: "https://docs.middleware.io/agent-installation/kubernetes",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Kubernetes
            Infrastructure data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/kubernetes/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Kubernetes Documentation
            </a>
            for more information.
          </div>
        ),
        component: <KubernetesIntegration />,
      },
    },
    {
      label: "Docker",
      link: "docker",
      prefixicon: <DockerIcon />,
      pageProps: {
        title: "Docker",
        bgColor: `rgba(29, 99, 237, ${borderOpacity})`,
        component: <DockerIntegration />,
        bodyTitle: "Installing Middleware Agent via Docker",
        docLink: "https://docs.middleware.io/agent-installation/docker",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Infrastructure
            data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/docker"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Docker Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "Linux",
      link: "linux",
      prefixicon: <LinuxIcon />,
      pageProps: {
        title: "Linux",
        bgColor: `${themeMode === "dark" ? "rgba(255, 193, 7, 0.5)" : "rgba(38, 50, 56, 0.1)"}`,
        // bodyTitle: "Installing MW Agent on Linux",
        component: <LinuxIntegration packageType="unknown" os="linux" />,
        bodyTitle: "Installing Middleware Agent on Linux",
        docLink: "https://docs.middleware.io/agent-installation/linux",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Infrastructure
            data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/linux/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Linux Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "CentOS",
      link: "cent-os",
      prefixicon: <CentOsIcon />,
      pageProps: {
        title: "CentOS",
        bgColor: `rgba(239, 167, 36, ${borderOpacity})`,
        component: <LinuxIntegration packageType="rpm" os="cent-os" />,
        bodyTitle: "Installing Middleware Agent on CentOS",
        docLink: "https://docs.middleware.io/agent-installation/linux",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Infrastructure
            data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/linux/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Linux Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "Debian",
      link: "debian",
      prefixicon: <DebianIcon />,
      pageProps: {
        title: "Debian",
        bgColor: `rgba(168, 0, 48, ${borderOpacity})`,
        component: <LinuxIntegration packageType="deb" os="debian" />,
        bodyTitle: "Installing Middleware Agent on Debian",
        docLink: "https://docs.middleware.io/agent-installation/linux",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Infrastructure
            data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/linux/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Linux Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "Ubuntu",
      link: "ubuntu",
      prefixicon: <UbuntuIcon />,
      pageProps: {
        title: "Ubuntu",
        bgColor: `rgba(233, 84, 32, ${borderOpacity})`,
        component: <LinuxIntegration packageType="deb" os="ubuntu" />,
        bodyTitle: "Installing Middleware Agent on Ubuntu",
        docLink: "https://docs.middleware.io/agent-installation/linux",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Infrastructure
            data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/linux/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Linux Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "Red Hat",
      link: "red-hat",
      prefixicon: <RedHatIcon />,
      pageProps: {
        title: "Red Hat",
        bgColor: `rgba(238, 0, 0, ${borderOpacity})`,
        component: <LinuxIntegration packageType="rpm" os="red-hat" />,
        bodyTitle: "Installing Middleware Agent on RedHat",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Infrastructure
            data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/linux/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Linux Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "Fedora",
      link: "fedora",
      prefixicon: <FedoraOsIcon />,
      pageProps: {
        title: "Fedora",
        bgColor: `rgba(41, 65, 114, ${borderOpacity})`,
        component: <LinuxIntegration packageType="rpm" os="cent-os" />,
        bodyTitle: "Installing Middleware Agent on Fedora",
        docLink: "https://docs.middleware.io/agent-installation/linux",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Infrastructure
            data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/linux/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Linux Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "Amazon Linux",
      link: "amazon-linux",
      prefixicon: <AmazonLinuxOsIcon />,
      pageProps: {
        title: "Amazon Linux",
        bgColor: `rgba(239, 167, 36, ${borderOpacity})`,
        component: <LinuxIntegration packageType="rpm" os="cent-os" />,
        bodyTitle: "Installing Middleware Agent on Amazon Linux",
        docLink: "https://docs.middleware.io/agent-installation/linux",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Infrastructure
            data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/linux/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Linux Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "AlmaLinux",
      link: "alma-linux",
      prefixicon: <AlmaLinuxIcon />,
      pageProps: {
        title: "AlmaLinux",
        bgColor: `rgba(134, 218, 47, ${borderOpacity})`,
        component: <LinuxIntegration packageType="rpm" os="alma-linux" />,
        bodyTitle: "Installing Middleware Agent on AlmaLinux",
        docLink: "https://docs.middleware.io/agent-installation/linux",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Infrastructure
            data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/linux/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Linux Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "Rocky Linux",
      link: "rocky-linux",
      prefixicon: <RockyLinuxIcon />,
      pageProps: {
        title: "Rocky Linux",
        bgColor: `rgba(16, 185, 129, ${borderOpacity})`,
        component: <LinuxIntegration packageType="rpm" os="rocky-linux" />,
        bodyTitle: "Installing Middleware Agent on Rocky Linux",
        docLink: "https://docs.middleware.io/agent-installation/linux",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Infrastructure
            data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/linux/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Linux Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "SUSE",
      link: "suse",
      prefixicon: <SuseIcon />,
      pageProps: {
        title: "SUSE",
        bgColor: `rgba(2, 211, 95, ${borderOpacity})`,
        component: <LinuxIntegration packageType="rpm" os="suse" />,
        bodyTitle: "Installing Middleware Agent on SUSE Linux",
        docLink: "https://docs.middleware.io/agent-installation/linux",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Infrastructure
            data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/linux/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Linux Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "Oracle Linux",
      link: "oracle-linux",
      prefixicon: <OracleLinuxIcon />,
      pageProps: {
        title: "Oracle Linux",
        bgColor: `rgba(199, 70, 52, ${borderOpacity})`,
        component: <LinuxIntegration packageType="rpm" os="oracle-linux" />,
        bodyTitle: "Installing Middleware Agent on Oracle Linux",
        docLink: "https://docs.middleware.io/agent-installation/linux",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Infrastructure
            data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/linux/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Linux Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "CoreOS",
      link: "fedora-coreOS",
      prefixicon: <FedoraCoreOsIcon />,
      pageProps: {
        title: "CoreOS",
        bgColor: `rgba(60, 110, 180, ${borderOpacity})`,
        component: (
          <FedoraCoreContainerIntegration packageType="rpm" os="cent-os" />
        ),
        bodyTitle: "Installing Middleware Agent on Fedora CoreOS",
        docLink: "https://docs.middleware.io/agent-installation/linux",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Infrastructure
            data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/linux/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Linux Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "Flatcar Linux",
      link: "flatcar-linux",
      prefixicon: <FlatcarContainerLinuxIcon />,
      pageProps: {
        title: "Flatcar Linux",
        bgColor: `rgba(9, 186, 200, ${borderOpacity})`,
        component: (
          <FlatcarContainerIntegration packageType="rpm" os="cent-os" />
        ),
        bodyTitle: "Installing Middleware Agent on Flatcar Container Linux",
        docLink: "https://docs.middleware.io/agent-installation/linux",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Infrastructure
            data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/linux/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Linux Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "Windows",
      link: "windows",
      prefixicon: <WindowsIcon />,
      pageProps: {
        title: "Windows",
        bgColor: `rgba(5, 166, 240, ${borderOpacity})`,
        component: <WindowsIntegration />,
        bodyTitle: "Installing Middleware Agent on Windows",
        docLink: "https://docs.middleware.io/agent-installation/windows",
        bodyDesc: (
          <div>
            Run the Middleware Agent to collect and analyze your Infrastructure
            data. Refer to the
            <a
              href="https://docs.middleware.io/agent-installation/windows/"
              target={"_blank"}
              rel={"noreferrer"}
            >
              Windows Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "AWS ECS",
      link: "aws-ecs",
      prefixicon: <AwsEcsSvg />,
      pageProps: {
        title: "AWS ECS",
        bgColor: `rgba(255, 152, 0, ${borderOpacity})`,
        component: <AwsEcsIntegration />,
        bodyTitle: "Installing Middleware Agent on AWS ECS",
        docLink: "https://docs.middleware.io/integrations/aws-ecs-monitoring",
        bodyDesc: (
          <div>
            Observe your AWS ECS machine and the applications running inside
            them. Refer
            <a
              href="https://docs.middleware.io/integrations/aws-ecs-monitoring"
              target={"_blank"}
              rel={"noreferrer"}
            >
              AWS ECS Documentation
            </a>
            for more information.
          </div>
        ),
      },
    },
    {
      label: "Third Party Agents",
      link: "",
      prefixicon: <DatadogIcon />,
      subItems: [
        {
          label: "Datadog",
          link: "datadog",
          prefixicon: <DatadogIcon />,
          pageProps: {
            title: "Datadog",
            bgColor: `rgba(127, 17, 224, ${borderOpacity})`,
            component: <DatadogIntegration serverlessSupport={true} />,
            bodyTitle: "Sending data from Datadog agent into Middleware",
            docLink: "#",
            bodyDesc: (
              <div>
                Middleware platform supports ingestion of APM traces and logs
                from the Datadog Agent. We use the Datadog agent's dual shipping
                feature to make this work.
              </div>
            ),
          },
        },
        {
          label: "Logstash",
          link: "elastic-logstash",
          prefixicon: <ElasticLogstashIcon />,
          pageProps: {
            title: "Logstash",
            bgColor: `rgba(244, 189, 25, ${borderOpacity})`,
            component: <ElasticLogStashIntegration />,
            bodyTitle: "Sending data from Logstash agent into Middleware",
            docLink: "https://docs.middleware.io/agent-installation/kubernetes",
            bodyDesc: (
              <div>
                Run the Middleware Agent to collect and analyze your
                Infrastructure data. Refer to the
                <a
                  href="https://docs.middleware.io/agent-installation/linux/"
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  LogStash Documentation
                </a>
                for more information.
              </div>
            ),
          },
        },
      ],
    },
  ];
};

export const Sections: InstallationSections[] = [
  {
    title: "Agent",
    prefixicon: <AgentIcon />,
    link: "agent",
    items: AgentItems,
  },
  {
    title: "APM",
    prefixicon: <ApmIcon />,
    link: "apm",
    items: () => apmItems().sort((a, b) => a.label.localeCompare(b.label)),
  },
  {
    title: "Integration",
    prefixicon: <IntegrationIcon />,
    link: "integrations",
    items: IntegrationItems,
  },
  {
    title: "RUM",
    prefixicon: <RumIcon />,
    link: "rum",
    items: RumItems,
  },
];
