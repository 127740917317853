import {
  AddUpdateWidgetBuilder,
  MetricTypeEnum,
  FilterOp,
  RequestParam,
} from "views/modules/builder/entities/builder.entities";
import { DateRange, QueryParams } from "../../routes/model";
import { WithExpr } from "views/modules/builder/entities/extra.entities";

export interface GlobalSearchProps {
  caller: string;
  placeholder?: string;
  extraBtnLabel?: string;
  extraBtnPrefixIcon?: JSX.Element;
  onExtraBtnClick?: () => void;
  extraJSXElement?: React.ReactNode;
  hideGroupByLabel?: boolean;
  allowConditionalOperators?: boolean;
  includeMetricTypes?: MetricTypeEnum[];
  extraAttributes?: OptionsType[];
  customFilters?: QueryParams;
  isCustom?: boolean;
  onChange?: (e: QueryParams) => void;
  uniqueId: string;
  handleRunQuery?: (
    config: AddUpdateWidgetBuilder | undefined,
    dateRange: DateRange | undefined
  ) => void;
  clearTableData?: () => void;
  regexSearch?: string;
}
export interface GlobalSearchState {
  searchParams: GlobalSearchParams;
  searchActions: MwRouteActions;
}
export interface GlobalSearchParams {
  search?: string;
}
export interface MwRouteActions {
  handleSearch: (search: string) => void;
}

export type MetricsType = Record<
  string,
  {
    count?: string | number;
    input?: string | number;
    inputLen?: string | number;
    label?: string;
    type?: string | number;
    values?: string[];
  }
>;

export enum SelectionType {
  Attributes = "ATTRIBUTES",
  Operator = "OPERATOR",
  Value = "VALUE",
}

export interface OptionsType {
  value: string;
  label: string;
  options?: string[];
}

interface FilterType {
  attribute: string;
  operator: FilterOp;
  value: string;
}
export interface QueryResponse {
  question_id: string;
  generated_query: {
    metric: string;
    aggregation: string;
    group_by?: string[];
    resource_type: string;
    filter_required: boolean;
    filters: FilterType[];
    params?: RequestParam[];
    sorting?: WithExpr;
    limit?: WithExpr;
  };
}
